import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { ToastContainer } from "react-toastify";
import { Layout } from './components/Layout';

// general links
import { Home } from './components/Home';
import { WelcomePage } from './components/WelcomePage';
import { AccountCreate } from './components/AccountCreate';
import { Authenticate } from './components/Authenticate';
import { EnterEmail } from './components/EnterEmail';
import { NotAuthorized } from './components/NotAuthorized';
import { Success } from './components/Success';
import { Timeout } from './components/Timeout';
import { Verify } from './components/Verify';
import { VerifyPhone } from './components/VerifyPhone';
import { Logout } from './components/Logout';

// admin links
//import ProtectedRoute from "./components/common/protectedRoute";
import { ClientSearch } from "./components/Admin/ClientSearch";
import { ClientProfile } from "./components/Admin/ClientProfile";
import { UserSearch } from "./components/Admin/UserSearch";
import { UserProfile } from "./components/Admin/UserProfile";
import { ViewLogs } from "./components/Admin/ViewLogs";
import { Settings } from "./components/Admin/Settings";

import { ErrorPage } from './components/ErrorPage';
import { TokenExpired } from './components/TokenExpired';

import "react-toastify/dist/ReactToastify.css";
import './custom.css'
import { datadogRum } from '@datadog/browser-rum';
datadogRum.startSessionReplayRecording();

export default class App extends Component {
    state = {};

    async componentDidMount() {
        var domain = window.location.host.split(".")[0];
        var env = 'local';
        switch (domain) {
        case 'otp-dev':
            env = 'dev';
            break;
        case 'otp-stg':
            env = 'stage';
            break;
        case 'otp-qa':
            env = 'qa';
            break;
        case 'otp':
            env = 'production';
            break;
        default:
            env = 'local';
            break;
        }

        datadogRum.init({
                applicationId: '82c7e76d-b044-4017-828b-d9ba4ffc9e3c',
                clientToken: 'puba111bd892ea04a21910d3b51a8df3f6d',
                site: 'ddog-gov.com',
                service: 'OTP.WebUI',
                project: "OTP",
                env: env,
                sampleRate: 100,
                premiumSampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel: 'allow',

                beforeSend: (event, context) => {
                    if (event.type === 'resource' && event.resource.type === 'xhr') {
                        event.context = {
                            ...event.context,
                            responseHeaders: context.response?.headers ?? "",
                            xhrresponse: context.xhr.response
                        };
                    }
                }
            }
        );

        datadogRum.addRumGlobalContext(
            "domain",
            domain
        );
    }

    routes = function ()  {
        return (
            <React.Fragment>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    //autoClose={false} // debugging
                    theme="colored"
                />
                <Layout>
                    <Switch>
                        <Route path='/' exact component={WelcomePage} />
                        <Route path='/login' exact component={Home} />
                        <Route path='/home' exact component={Home} />
                        <Route path='/AccountCreate' component={AccountCreate} />
                        <Route path='/Authenticate/:email' component={Authenticate} />
                        <Route path='/EnterEmail' component={EnterEmail} />
                        <Route path='/NotAuthorized' component={NotAuthorized} />
                        <Route path='/Success' component={Success} />
                        <Route path='/Timeout' component={Timeout} />
                        <Route path='/Verify' component={Verify} />
                        <Route path='/VerifyPhone' component={VerifyPhone} />

                        <Route path='/ClientSearch' component={ClientSearch} />
                        <Route path='/ClientProfile' component={ClientProfile} />
                        <Route path='/UserSearch' component={UserSearch} />
                        <Route path='/UserProfile' component={UserProfile} />
                        <Route path='/ViewLogs' component={ViewLogs} />
                        <Route path='/Settings' component={Settings} />
                        <Route path='/error/' component={ErrorPage} />
                        <Route path='/tokenExpired' component={TokenExpired} />
                        <Route path='/logout' exact component={Logout} />
                    </Switch>
                </Layout>
            </React.Fragment>
        );
    }

    render() {        
        return (this.routes());
    }
}
