import http from "./httpService";

export async function getSettings() {
    return http.post(`settings/getSettings`);
}

export async function updateSetting(body) {
    return http.post(`settings/updateSetting`, body);
}
export async function getSupportBannerSetting() {
    return http.get("settings/SupportBannerSetting");
}
export default {
    getSettings: getSettings,
    updateSetting: updateSetting,
    getSupportBannerSetting: getSupportBannerSetting
}