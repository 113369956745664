import React, { Fragment } from 'react';
import Joi from 'joi-browser';
import fileDownload from 'js-file-download';
import Form from "../common/form";
import DateTimeService from "../../services/dateTimeService";
import { LinkMenu } from './LinkMenu';
//import sortingServices from '../../services/sortingServices';
import { Tabs, Tab, Badge } from 'react-bootstrap';
import { Redirect, Link } from "react-router-dom";
import { toast } from "react-toastify";
import _DbLogs from "./_DbLogs";
import _FileLogs from "./_FileLogs";

const loader = (<div className="loader" style={{ position: "fixed", top: "30%", left: "45%", color: "blue" }}>Loading...</div>);

export class ViewLogs extends Form {
    static displayName = ViewLogs.name;

    schema = {
        fromDate: Joi.string().required().error(() => {
            return { message: "Required." };
        }),
        toDate: Joi.string().trim().required().error(() => {
            return { message: "Required." };
        }),
        message: Joi.string().allow('').optional(),
        fileFromDate: Joi.string().required().error(() => {
            return { message: "Required." };
        }),
        fileToDate: Joi.string().trim().required().error(() => {
            return { message: "Required." };
        })
    }

    loader = loader;
    //sortedRows = [];

    constructor(props) {
        super(props);
        this.state = {
            //Db Logs
            loading: false,          
            data: {
                fromDate: DateTimeService.toDateTimeStr(new Date(Date.now() - 24 * 60 * 60 * 1000)),
                toDate: DateTimeService.toDateTimeStr(new Date(Date.now() + (3 * 60 * 60 * 1000))),
                message: "",

                fileFromDate: DateTimeService.toDateTimeStr(new Date(Date.now() - 24 * 60 * 60 * 1000)),
                fileToDate: DateTimeService.toDateTimeStr(new Date(Date.now() + (3 * 60 * 60 * 1000))),
            },
            order: "desc",
            orderBy: "date",
            rows: [],
            uiRows: [],
            errors: {},
            pageEditMode: true,
            rowsPerPage: 100,
            rowOpened: { row: null, detailId: null },
            page: 0,
            find: "",
            //File Logs
            fileData: {
                fromDate: DateTimeService.toDateTimeStr(new Date(Date.now() - 24 * 60 * 60 * 1000)),
                toDate: DateTimeService.toDateTimeStr(new Date(Date.now() + (3 * 60 * 60 * 1000)))
                //message: ""
            },
            fileLoading: false,
            fileOrder: "desc",
            fileOrderBy: "date",
            fileRows: [],
            fileUiRows: [],
            fileRowsPerPage: 100,
            filePage: 0,
            fileFind: ""
        }
    }

    async componentDidMount() {
        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }
    }

    handleDownloadSuccess(res, fileId, filename) {
         fileDownload(res.data, filename);
    }

    handleDownloadError(error, fileId) {
         toast.error(`Error downloading file.`);
    }

    render() {

        //this.sortedRows = sortingServices.stableSort(this.state.uiRows, sortingServices.getComparator(this.state.order, this.state.orderBy));
        
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-md userprofile-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>View Logs</h1>

                                    <LinkMenu {...this.props.location.state} />
                                </div>

                                <div className="row">
                                    <div className={`internal-page-tabs tab-content ${this.state.isEdit ? "col-xl-9" : "col-xl-12"}`}>
                                        <Tabs defaultActiveKey="dbLogs" activeKey={this.state.selectedTab} onSelect={(eventKey) => { this.setState({ selectedTab: eventKey }) }} transition={false} id="solTab">
                                            <Tab eventKey="dbLogs" title="DB Logs">
                                                {_DbLogs.getDbLogs(this)}
                                            </Tab>

                                            <Tab eventKey="clientScope" title="Log Files">
                                                {_FileLogs.getFileLogs(this)}
                                                {/*<div id="client-scope" role="tabpanel" aria-label="client-scope-tab">*/}
                                                {/*    <div className="tile-section form-section">*/}
                                                {/*        <form>*/}
                                                {/*            <div className="container-content">*/}
                                                {/*                <div className="search-container">*/}
                                                {/*                    <div className="info-wrapper">*/}
                                                {/*                        <div className="form-row">*/}
                                                {/*                            {this.renderInput("fromDate", "From Date", "info col-lg-5", "text", true)}*/}
                                                {/*                            {this.renderInput("toDate", "To Date", "info col-lg-5", "text", true)}*/}
                                                {/*                            <Link to="/userprofile">*/}
                                                {/*                                <button className="btn btn-primary search">Search</button>*/}
                                                {/*                            </Link>*/}
                                                {/*                        </div>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}

                                                {/*                <div className="results-container">*/}
                                                {/*                    <div className="info-wrapper">*/}
                                                {/*                        log results table*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </form>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

