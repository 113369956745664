import React, { Component } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { BrowserCheckBanner } from "./common/browserCheckBanner";
import { BookmarkBanner } from "./common/bookmarkBanner";
import { BackButton } from './BackButton';
import { SupportNoticeBanner } from "./common/supportNoticeBanner";
import SettingsService from '../services/settingsService';


const isIE = (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1);
const isHome = (window.location.pathname === '/Home' || window.location.pathname === '/home' );

export class Layout extends Component {

    constructor(props) {
        super(props);
        this.status = {};
        this.state = {
            supportBanner: {}
        };
    }
    async componentDidMount() {
        const { data: supportBanner } = await SettingsService.getSupportBannerSetting();
        this.setState({ supportBanner });
    }
    renderSupportBanner() {
        
        const isActive = this.state.supportBanner.isActive;
        const supportBannerMessage = this.state.supportBanner.supportBannerMessage;
        return isActive ? <SupportNoticeBanner supportNoticeBannerMessage={supportBannerMessage} /> : <React.Fragment />;
    }
    render() {
        return (            
            <div>
                <BackButton />
                {this.renderSupportBanner()}
                {isHome && <BookmarkBanner />}
                {isIE && <BrowserCheckBanner />}
                <Header />
                <div style={{ marginLeft: "0", marginRight: "0" }} >{this.props.children}</div>
                <Footer />
            </div>
        );
    }
}
