import React, { Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Joi from 'joi-browser';
import Form from "../common/form";
import ToggleButton from '../common/toggleButton';
import userServices from '../../services/userServices';

import { Redirect, Link } from "react-router-dom";
import { Tabs, Tab, Badge } from 'react-bootstrap';
import { LinkMenu } from './LinkMenu';
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { toast } from "react-toastify";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 300,
        fontSize: '14px',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    arrow: {
        color: '#000',
    }
}))(Tooltip);

export class UserProfile extends Form {
    static displayName = UserProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            data: {
                profileLocked: "0",
                profileLockedDate: "",
                profileUserName: "",
                profileUserPrincipalName: "",
                profileEmail:"",
                accountFirstName: "",
                accountMiddleName: "",
                accountLastName: "",
                companyName: "",
                companyStreet: "",
                companyCity: "",
                companyState: "",
                companyZip: "",
                companyCountry: "",
                accountPhone: "",
                accountTextSms: "0",
                selectedRole: 0,
                selectedApplication:"1"
            },
            userProfileId: this.props.location.state?.userProfileId == null ? 0 : props.location.state?.userProfileId,
            country: 'US',
            accountPhone: '',
            errors: {},
            pageEditMode: true,
            userAccessLoaded: false,
            accountTextSmsSaved: "",
            selectedTab: 'userProfile',
            role: [{ id: 0, name: "User" }, { id: 1, name: "Admin" }],
            applicationList: [],
            userAccessList:[]
        }

        this.schema = {
            profileLocked: Joi.number(),
            profileEmail: Joi.string().trim().required().error(() => { return { message: "Profile Email is required." } }),
            profileLockedDate: Joi.string().trim().allow(null, ''),
            profileUserName: Joi.string().trim().max(150).required().error(() => {
                return { message: "User Name required with maximum 150 characters." };
            }),
            profileUserPrincipalName: Joi.string().trim().max(150).required().error(() => {
                return { message: "User Principle Name required with maximum 150 characters." };
            }),
            accountFirstName: Joi.string().trim().max(100).required().error(() => {
                return { message: "First Name required with maximum 100 characters." };
            }),
            accountMiddleName: Joi.string().max(50).allow(null,'').error(() => {
                return { message: "Middle Name has maximum 50 characters." }
            }),
            accountLastName: Joi.string().trim().max(100).required().error(() => {
                return { message: "Last Name required with maximum 100 characters." };
            }),
            companyName: Joi.string().trim().max(100).required().error(() => { return { message: "Company Name is required with maximum 100 characters." } }),
            companyStreet: Joi.string().trim().max(100).required().error(() => { return { message: "Street Address is required with maximum 100 characters." } }),
            companyCity: Joi.string().trim().max(100).required().error(() => { return { message: "City is required with maximum 100 characters." } }),
            companyState: Joi.string().trim().max(50).required().error(() => { return { message: "State is required with maximum 50 characters." } }),
            companyZip: Joi.string().trim().max(50).required().error(() => { return { message: "Zip is required with maximum 50 characters." } }),
            companyCountry: Joi.string().trim().required().error(() => { return { message: "Country is required." } }),
            accountPhone: Joi.string().when('accountTextSms', {
                is: 1,
                then: Joi.string().min(5).required().error(() => { return { message: "Phone Number is required" } }),
                otherwise: Joi.allow('').optional()
            }),
            selectedApplication: Joi.string().default("1"),
            accountTextSms: Joi.number(),
            selectedRole: Joi.string().default("0"),
            accountId: Joi.number().optional()
        };

        this.handleToggleButtonChange = this.handleToggleButtonChange.bind(this);
        this.handleTabSelection = this.handleTabSelection.bind(this);
        this.handleSaveProfile = this.handleSaveProfile.bind(this);
        this.mapToProfileData = this.mapToProfileData.bind(this);
        this.handleSaveUserAccess = this.handleSaveUserAccess.bind(this);
        this.handleDeleteUserAccess = this.handleDeleteUserAccess.bind(this);
    }

    handleToggleButtonChange(name, value) {
        const data = Object.assign({}, this.state.data);
        data[name] = value === "Yes" ? 1 : 0;
        this.setState({ data });
    }

     trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
     wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }

    handleTabSelection(eventKey) {
        //console.log("handle tab selection:", eventKey);
        this.setState({ selectedTab: eventKey });

        if (eventKey === "userRoleAccess" && !this.state.userAccessLoaded) {

            //console.log(":Fetching userAccess from server");

            userServices.getUserAccess(this.state.userProfileId).then((response) => {
                //console.log(response);

                const appList = response.data.appList.map(({ applicationGroupId: id, applicationGroupName:name})=>({id,name}));
                const userAccessList = response.data.userAccessList;

                //appList.unshift({ id: 0, name: "Select Application" });
                //console.log(appList);
                const data = Object.assign({}, this.state.data);
                data.selectedApplication = appList[0].id.toString();

                //console.log(userAccessList);
                this.setState({ userAccessLoaded: true, applicationList: appList, userAccessList, data});
                

            }).catch((error) => {

                toast.error("Error in fetching user access");
                //console.log(error);
            });

            
        }
    }

    async handleSaveUserAccess(e) {
        e.preventDefault();
        
        const selectedAppId = parseInt(this.state.data.selectedApplication) || 1;
        var selectedApplicationName = this.state.applicationList.find(x => x.id === selectedAppId).name;
        //console.log("selectedApplicationName", selectedApplicationName);
        if (this.state.userAccessList.some(ua => ua.applicationName === selectedApplicationName)) {
            //console.log("duplicate user access not allowed");
            const errors = Object.assign({}, this.state.errors);
            errors["selectedApplication"] = "Duplicate user access not allowed.";
            this.setState({  errors});
            return;
        }

        const request= {
            userProfileId: this.state.userProfileId,
            applicationGroupId: selectedAppId ,
            roleId: parseInt(this.state.data.selectedRole)||0
        }
        //console.log("handle save user access:", request);

        userServices.updateUserAccess(request).then((response) => {
            //console.log(response);

            const updateResult = response.data;

            if (updateResult.isSuccess) {
                const data = updateResult.data;
                this.setState({ userAccessList: data, errors: {} });
                toast.success(updateResult.message);
            }

            if (!updateResult.isSuccess) {
               
                toast.error(updateResult.message);
            }

        }).catch((error) => {

            toast.error("Error in fetching user access");
            //console.log(error);
        });
    }

    async handleDeleteUserAccess(e, userAccessId) {
        e.preventDefault();
        //console.log("handle delete user access:", userAccessId);

        const request = {
            userProfileId: this.state.userProfileId,
            userAccessId: userAccessId
        }
        

        userServices.removeUserAccess(request).then((response) => {
            //console.log(response);

            const result = response.data;

            if (result.isSuccess) {
                toast.success(result.message);
                const data = result.data;
                this.setState({ userAccessList: data });
                
            }

            if (!result.isSuccess) {

                toast.error(result.message);
            }

        }).catch((error) => {

            toast.error("Error in removing user access");
            //console.log(error);
        });
    }

    async handleSaveProfile(e) {
        e.preventDefault();
        //console.log("handle save profile");

        //if (this.state.accountPhone !== this.state.data.accountPhone) {
        //    const data = Object.assign({}, this.state.data);
        //    data.accountTextSms = 0;
        //    this.setState({ data });
        //}
        const errors = this.validate();        

        if ( errors && Object.keys(errors).length > 0) {
            console.log(errors);
            this.setState({ errors: errors || {} });
            return;
        }

        //console.log("After Validation");
        var request = {
            //userProfileId: this.state.userProfileId,
            email: this.state.data.profileEmail,
            firstName: this.state.data.accountFirstName,
            middleName: this.state.data.accountMiddleName,
            lastName: this.state.data.accountLastName,
            phone: this.state.data.accountPhone,
            company: this.state.data.companyName,
            city: this.state.data.companyCity,
            state: this.state.data.companyState,
            zip: this.state.data.companyZip,
            streetAddress: this.state.data.companyStreet,
            country: this.state.data.companyCountry,
            isAccountLocked: this.state.data.profileLocked === 1 ? true : false,
            userPrincipalName: this.state.data.profileUserPrincipalName,
            isPhoneVerified: this.state.data.accountTextSms ? true : false
    }

        //console.log("Updated data:", request);
        //return;
        await userServices.updateProfile(request).then(response => {
                if (!response.data.isSuccess) {
                    toast.error("Error : Updating user.");
                    return false;
                }

                const user = Object.assign({}, response.data.data);
                this.setState({ data: this.mapToProfileData(user) });

                toast.success("User Updated successfully");

            })
            .catch(error => {
                //console.log(error);
                toast.error("Error : Updating user.");
            });
    }

    async componentDidMount() {
        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }

        //console.log(`fetch user data for profile id ${this.state.userProfileId}`);
        //const userProfileId=
        userServices.getUserById(this.state.userProfileId).then((response) => {
            
            //console.log(response.data);

            this.setState({ data: this.mapToProfileData(response.data) });


        }).catch((error) => {

            toast.error("Error while fetching user data");
            //console.log(error);
        });
    }

    mapToProfileData({ isAccountLocked, accountLockedDate, email, userName, userPrincipalName, firstName, middleName, lastName, company,
        streetAddress, city, state, zip, country, phone, isPhoneVerified }) {

        //this.setState({ accountPhone: phone });
        return {
            profileEmail:email,
            profileLocked: isAccountLocked?1:0,
            profileLockedDate: accountLockedDate,
            profileUserName: userName,
            profileUserPrincipalName: userPrincipalName,
            accountFirstName: firstName,
            accountMiddleName: middleName,
            accountLastName: lastName,
            companyName: company,
            companyStreet: streetAddress,
            companyCity: city,
            companyState: state,
            companyZip: zip,
            companyCountry: country && (country.includes("America") || country.includes("United States")) ? "United States" : country,
            accountPhone: phone,
            accountTextSms: isPhoneVerified ? 1 : 0,
            selectedRole: "0", //default
            selectedApplication:"2" //default
            }
    }
    selectCountry(val) {
        //this.setState({ data.companyCountry: val });
        const data = Object.assign({}, this.state.data);
        if (val !== "") {
            this.state.errors["companyCountry"] = "";
        }
        //console.log(val);
        data['companyCountry'] = val;
        this.setState({ data });
    }
    setPhone(val) {
        const data = Object.assign({}, this.state.data);
        data['accountPhone'] = val;
        this.setState({ data });
        //console.log(val);
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="content">
                        <div className="main-content centered-content static-page">
                            <div className="container-md userprofile-container">
                                <div className="content-container">
                                    <div className="container-header">
                                        <h1>Manage User Profile</h1>
                                        <span className="header-subheader">{this.state.data.profileEmail}</span>
                                        <LinkMenu {...this.props.location.state}/>
                                    </div>

                                    <div className="row">
                                        <div className={`internal-page-tabs tab-content ${this.state.isEdit ? "col-xl-9" : "col-xl-12"}`}>
                                            <Tabs defaultActiveKey="userProfile" activeKey={this.state.selectedTab} onSelect={(eventKey) => { this.handleTabSelection(eventKey) }} transition={false} id="solTab">
                                                <Tab eventKey="userProfile" title="USER PROFILE">
                                                    <div id="user-profile" role="tabpanel" aria-label="user-profile-tab">
                                                        <div className="tile-section form-section user-profile">

                                                            <form>
                                                                <div className="container-content">
                                                                    <div className="profileinfo-container">
                                                                        <h2>Profile Information</h2>
                                                                        <div className="info-wrapper">
                                                                            <div className="form-row">
                                                                                <div className="info col-lg-6" id="info-accountLocked">
                                                                                    <ToggleButton
                                                                                        name="profileLocked"
                                                                                        label="Account Locked?"
                                                                                        options={['Yes', 'No']}
                                                                                        disabled={false}
                                                                                        pageEditMode={this.state.pageEditMode}
                                                                                        value={this.state.data.profileLocked === 1 ? "Yes" : "No"}
                                                                                        handleChange={this.handleToggleButtonChange}
                                                                                        widthClass="toggle-small"
                                                                                    />
                                                                                </div>
                                                                                {this.renderInput("profileLockedDate", "Account Locked On", "info col-lg-6", "text",false,null,true)}
                                                                            </div>
                                                                            <div className="form-row">
                                                                                {this.renderInput("profileUserName", "User Name", "info col-lg-6", "text",true,null,true )}
                                                                                {this.renderInput("profileUserPrincipalName", "User Principal Name", "info col-lg-6", "text", true)}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="userinfo-container">
                                                                        <h2>User Information</h2>
                                                                        <div className="info-wrapper">
                                                                            <div className="form-row">
                                                                                {this.renderInput("accountFirstName", "First Name", "info col-lg-4", "text")}
                                                                                {this.renderInput("accountMiddleName", "Middle Name", "info col-lg-4", "text", false)}
                                                                                {this.renderInput("accountLastName", "Last Name", "info col-lg-4", "text")}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="companyinfo-container">
                                                                        <h2>Company Information</h2>
                                                                        <div className="info-wrapper">
                                                                            <div className="form-row">
                                                                                {this.renderInput("companyName", "Company Name", "info col-lg-4", "text")}
                                                                                {this.renderInput("companyStreet", "Street Address", "info col-lg-4", "text")}
                                                                                {this.renderInput("companyCity", "City", "info col-lg-4", "text")}
                                                                            </div>
                                                                            <div className="form-row">
                                                                                {this.renderInput("companyState", "State/Province", "info col-lg-4", "text")}
                                                                                {this.renderInput("companyZip", "Zip", "info col-lg-4", "text")}
                                                                                {/*{this.renderInput("companyCountry", "Country", "info col-lg-4", "text")}*/}
                                                                                <div className="info col-lg-4">
                                                                                    <label htmlFor="companyCountry">Country</label>
                                                                                    <CountryDropdown
                                                                                        value={this.state.data.companyCountry}
                                                                                        valueType={"full"}
                                                                                        onChange={(val) => this.selectCountry(val)}
                                                                                        name="companyCountry"
                                                                                        priorityOptions={["US", "CA", "GB"]}
                                                                                        defaultOptionLabel=""
                                                                                    />
                                                                                    {this.state.errors["companyCountry"] && <small className="error-text">{this.state.errors["companyCountry"] }</small>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="phoneinfo-container">
                                                                        <h2>Phone Information</h2>
                                                                        <div className="info-wrapper">
                                                                            <div className="form-row">
                                                                                {/*{this.renderInput("accountPhone", "Phone", "info col-lg-4", "text", false)}*/}
                                                                                <div className="info col-lg-4">
                                                                                    <label htmlFor="accountPhone">Phone <small>Optional</small></label>
                                                                                    <PhoneInput
                                                                                        name="accountPhone"
                                                                                        disabled
                                                                                        country={'us'}
                                                                                        value={this.state.data.accountPhone}
                                                                                        inputClass="MuiInputBase-input MuiInput-input"
                                                                                        placeholder=""
                                                                                        // onChange={(val) => this.setPhone(val)}
                                                                                    />
                                                                                </div>
                                                                                <div className="info col-lg-4" id="info-textsms">
                                                                                    <ToggleButton
                                                                                        name="accountTextSms"
                                                                                        label="Use Phone for Text (SMS) Verification?"
                                                                                        options={['Yes', 'No']}
                                                                                        pageEditMode={this.state.pageEditMode}
                                                                                        value={this.state.data.accountTextSms === 1 ? "Yes" : "No"}
                                                                                        disabled={true}
                                                                                        handleChange={this.handleToggleButtonChange}
                                                                                        widthClass="toggle-small"
                                                                                    />
                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                Add mobile phone number to receive secure login code via Text(SMS) message
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        placement="right"
                                                                                        arrow
                                                                                    >
                                                                                        <div className="tooltip-trigger">?</div>
                                                                                    </HtmlTooltip>
                                                                                    <small>Note: Data rates may apply.</small>
                                                                                </div>
                                                                                <div className="info col-lg-4">
                                                                                    {this.state.data.accountTextSms === 1 &&
                                                                                        <div className="text-confirmation">User will be prompted to verify your phone number after saving</div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="container-buttons" style={{ justifyContent: 'space-between' }}>
                                                                        <Link to={{
                                                                            pathname: `/UserSearch`,
                                                                            state: {
                                                                                clientId: this.props.location.state.clientId,
                                                                                email: this.props.location.state.email,
                                                                                returnUrl: this.props.location.state.returnUrl
                                                                            }
                                                                        }} >
                                                                            <button className="btn cancel">Cancel</button>
                                                                        </Link>
                                                                        <button className="btn primary action" onClick={this.handleSaveProfile}>Save</button>

                                                                    </div>
                                                                </div>

                                                            </form>

                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="userRoleAccess" title="USER ROLE & ACCESS">
                                                    <div id="user-roles" role="tabpanel" aria-label="user-roles-tab">
                                                        <div className="tile-section form-section">
                                                            <form>
                                                                <div className="row params">
                                                                    {this.renderSelect("selectedRole", "User Role", this.state.role, "info col-lg-4 col-md-4")}
                                                                    {this.renderSelect("selectedApplication", "Application", this.state.applicationList, "info col-lg-5 col-md-5",true)}
                                                                    
                                                                    <div className="col-lg-2">
                                                                        <button className="btn primary action" onClick={this.handleSaveUserAccess}>Save</button>
                                                                        
                                                                    </div>

                                                                </div>
                                                            </form>
                                                            <div className="info-wrapper results">
                                                                <div className="col-lg-12">
                                                                    <table className="MuiTable-root" style={{ width: "100%" }}>
                                                                        <TableHead>
                                                                            <TableRow className="header">
                                                                                <TableCell key='ApplicationName' align='left' width='33%'>Application Name</TableCell>
                                                                                <TableCell key='UserRole' align='left' width='33%'>User Role</TableCell>
                                                                                <TableCell key='Action' align='right' width='33%'>Action</TableCell>
                                                                            </TableRow>
                                                                        </TableHead >
                                                                        <tbody className="MuiTableBody-root">
                                                                        {
                                                                                this.state.userAccessList.map((row, index) => (
                                                                                    <React.Fragment key={row.userAccessId}>
                                                                                        <tr className="MuiTableRow-root MuiTableRow-hover" style={this.trMain(index)}>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={this.wordWrap} data-label='ApplicationName'><div>{row.applicationName}</div></td>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='RoleName'><div>{row.roleName}</div></td>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" data-label='Action'><div className="action-icon"><button className="btn primary action delete" onClick={(e) => this.handleDeleteUserAccess(e, row.userAccessId)}>Delete</button></div></td>

                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}