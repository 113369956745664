import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";

import UserServices from '../services/userServices';
import HTTPStatusCodes from "../enums/HTTPStatusCodes";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export class Authenticate extends Component {
    static displayName = Authenticate.name;

    constructor(props) {
        super(props);
        this.state = {
            userProfile: [],
            isEmailRegistered: false,
            clientId: this.props.location.state?.clientId,
            clientName: this.props.location.state?.clientName,
            returnUrl: this.props.location.state?.returnUrl
        }
    }

    async componentDidMount() {
        
        //const email = this.props.match.params.email;
        //var data = UserServices.getUserByEmail(email).then((response) => {
        //    console.log("get user by email result",response);
        //    if (response.status !== HTTPStatusCodes.SUCCESS) {
        //       console.log("Err fetching User By email", response.data.message);
        //        return false;
        //    }
           
        //}).catch((error) => {
        //    console.log(error);
        //});


        if (!this.props.history.location.key) {
            //console.log("REDIRECT TO ERROR PAGE");
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
        } else {
            //console.log("OK, Redirected from client app");
        }

        try {
            //const search = this.props.location.search;
            //const returnUrl = encodeURIComponent(new URLSearchParams(search).get('ReturnUrl'));
            //this.setState({ returnUrl });
            const email = this.props.match.params.email;
            const { data, status } = await UserServices.getUserToVerifyByEmail(email);

            if (status !== HTTPStatusCodes.SUCCESS) {
                //console.log(status);
                this.setState({ isEmailRegistered: false });
                //console.log("Err fetching User By email");
                return ;
            }

            this.setState({ userProfile: data, isEmailRegistered:true});
            //console.log("Get User Data by email", data);

            let menu = document.getElementById('accountPhone');
            if (menu) {
                let children = menu.children[0].children;
                let item = children[1];
                let phoneFormatted = item['value'];
                this.setState({ phoneFormatted });
            }
            
            
        } catch (ex) {

            //console.log("Error happened in Authentication",ex);
        } 
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <span className="header-subheader">Sign in</span>
                                    <h1>Select authentication method</h1>
                                </div>

                                <div className="container-content">
                                    <div className="text">
                                        You will be sent a one-time authentication code to the method you
                                        select below.
                                    </div>
                                   <div className="authenticate-container">
                                        <div className="textsms" >
                                            {(this.state.userProfile && this.state.userProfile.isPhoneVerified) &&
                                                <Link to={{
                                                pathname: `/Verify`,
                                                search: `?ReturnUrl=${this.state.returnUrl}`,
                                                state: {
                                                    email: this.state.userProfile.email,
                                                    userProfileId: this.state.userProfile.userProfileId,
                                                    isSendSms: true,
                                                    clientId: this.state.clientId,
                                                    clientName: this.state.clientName,
                                                    phoneNumber: this.state.userProfile.phone,
                                                    isVerificationForAccountUpdate: false,
                                                    previousScreen: "Authenticate"
                                                }
                                                }} className="">
                                                    <div>
                                                        <img src="/images/icon-phone-green.png" alt="text/sms icon" />
                                                        <span>Text (SMS)</span>
                                                    </div>
                                                </Link>
                                            }

                                            {(this.state.userProfile && !this.state.userProfile.isPhoneVerified) &&
                                                <Link to='#' className= "disabled">
                                                    <div>
                                                        <img src="/images/icon-phone-gray.png" alt="text/sms icon" />
                                                        <span>Text (SMS)</span>
                                                    </div>
                                                    </Link>
                                            }

                                            {(this.state.userProfile && this.state.userProfile.isPhoneVerified)
                                                &&
                                                <span>
                                                {/*{this.state.userProfile.phone.substring(0, 2)} ({this.state.userProfile.phone.substring(2, 5)}) {this.state.userProfile.phone.substring(5, 8)}-{this.state.userProfile.phone.substring(8, this.state.userProfile.phone.length)}*/}
                                                {this.state.userProfile.phone}
                                                
                                                <span id="accountPhone">
                                                    <PhoneInput
                                                        inputProps={{
                                                            disabled: true,
                                                            disabledDropdown: true,
                                                            disabledCountryCode: true
                                                        }}
                                                        name="accountPhone"
                                                        value={this.state.userProfile.phone}
                                                        containerClass="verifyNumber"
                                                    />
                                                </span>
                                                </span>}
                                           {this.state.userProfile && !this.state.userProfile.isPhoneVerified &&
                                                <span><small>Add phone to account after login for Text (SMS) verification option</small></span>}
                                            <small className="data-rates">Note: Data rates may apply.</small>
                                        </div>
                                        <div className="email">
                                            {/*<Link to={`/Verify/1/${this.state.userProfile.email}?ReturnUrl=${this.state.returnUrl}`}>*/}
                                            <Link to={this.state.isEmailRegistered ? {
                                                pathname: `/Verify`,
                                                search: `?ReturnUrl=${this.state.returnUrl}`,
                                                state: {
                                                    email: this.state.userProfile.email,
                                                    userProfileId: this.state.userProfile.userProfileId,
                                                    isSendSms: false,
                                                    clientId: this.state.clientId,
                                                    clientName: this.state.clientName,
                                                    isVerificationForAccountUpdate: false,
                                                    previousScreen: "Authenticate"
                                                }
                                            } : "#"} className={this.state.isEmailRegistered ? "":"disabled"}>
                                                <div className={this.state.isEmailRegistered ? "view-logs" : ""}>
                                                    <img src={`${this.state.userProfile && this.state.isEmailRegistered ? "/images/icon-email-blue.png" : "/images/icon-email-gray.png"}`} alt="email icon" />
                                                    <span>Email</span>
                                                </div>
                                            </Link>
                                            <span>{this.state.userProfile && this.state.userProfile.email}</span>
                                        </div>
                                    </div>
                                    <div className="container-buttons" style={{ justifyContent: 'space-between' }} >
                                        <Link to={`/login?ReturnUrl=${this.state.returnUrl}`} >
                                            <button className="btn cancel">Cancel</button>
                                            </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
