import React, { Component } from "react";
import Mailto from './common/mailTo';

export class TokenExpired extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // errorMessage: props.location.state.errorMessage == null ? "" : props.location.state.errorMessage
        };
    }

    async componentDidMount() {

        const search = this.props.location.search;
        const returnUrl = new URLSearchParams(search).get('clientUri');
        this.setState({ returnUrl });
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Token Expired</h1>
                                </div>
                                <div className="container-content">
                                    <span className="header-subheader">Because you have been inactive, your token is expired. Please go to the application <a href={this.state.returnUrl}>Home</a> page and try to login again, or contact <Mailto email="NIAIDOTPTechSupport@mail.nih.gov" subject="OTP Login Issue" >Admin</Mailto>.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}