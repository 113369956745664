
String.prototype.trimSafe = function () {
    if (!this) return "";
    return this.valueOf().replace(/^\s+|\s+$/g, '');
}

function toStrSafe(str) {
    if (!str) return "";
    return str;
}

function trimEx(str) {
    if (!str) return "";
    return str.valueOf().replace(/^\s+|\s+$/g, '');
}

export default {
    toStrSafe: toStrSafe,
    trimEx: trimEx
}

