import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import Joi from 'joi-browser';
import Form from "./common/form";
import AuthService from '../services/authServices';
import { toast } from "react-toastify";

export class EnterEmail extends Form {
    static displayName = EnterEmail.name;
    constructor(props) {
        super(props);
        var reCAPTCHASite = process.env.REACT_APP_RECAPTCHASITE;
        
        this.state = {
            data: {
                Email: ""
            },
            errors: {},
            pageEditMode: true,
            _reCAPTCHA_site: reCAPTCHASite,
            _reCAPTCHA_site_key: "",
            _reCAPTCHA_site_action: "EnterEmail",
            _reCAPTCHA_token: "",
            clientId: this.props.location.state?.clientId,
            clientName: this.props.location.state?.clientName,
            returnUrl: this.props.location.state?.returnUrl
        }
        //this.handleNextClick = this.handleNextClick.bind(this);
        this.handleLoaded = this.handleLoaded.bind(this);
        this.schema = {
            Email: Joi.string().email().trim().max(100).required().error(() => { return { message: "Valid email required to create an account." }; }),
            accountTextSms: Joi.number()
        };
    }
    handleLoaded() {
        //window.grecaptcha.ready(_ => {
        //    window.grecaptcha
        //        .execute(this.state._reCAPTCHA_site_key, { action: this.state._reCAPTCHA_site_action })
        //        .then(token => {
        //            this.setState({ _reCAPTCHA_token: token });
        //        });
        //});
    }
    async componentDidMount() {
        //const search = this.props.location.search;
        //const returnUrl = encodeURIComponent(new URLSearchParams(search).get('ReturnUrl'));
        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }

        var captchaKey = await AuthService.getCaptchaKey();        
        this.setState({ _reCAPTCHA_site_key: captchaKey.data });
        //const script = document.createElement("script");
        //script.src = `${this.state._reCAPTCHA_site}=${this.state._reCAPTCHA_site_key}`;
        //script.addEventListener("load", this.handleLoaded);
        //document.body.appendChild(script);
    }
    doSubmit(event) {
        //Google reCAPTCHA check
        var isUserValid = false;
        var isEmail = this.isValidEmail(this.state.data.Email);
        if (!isEmail) {
            this.setState(prevState => (
                {
                    errors: {
                        ...prevState.errors,
                        Email: "Invalid email format."
                    }
                }));
            return false;
        }


        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(
                    this.state._reCAPTCHA_site_key,
                    {
                        action: this.state._reCAPTCHA_site_action
                    }
                )
                .then(token => {
                    this.setState(
                        {
                            _reCAPTCHA_token: token
                        }
                    );

                    //send Otp code comes here
                    var request = {
                        email: this.state.data.Email,
                        reCAPTCHToken: this.state._reCAPTCHA_token,
                        ReCAPTCHAction: this.state._reCAPTCHA_site_action
                    }
                    var data = AuthService.sendOtpCodeForNewUser(request).then((response) => {
                        //console.log(response);
                        if (!response.data.isSuccess) {
                            this.setState(prevState => (
                                {
                                    errors: {
                                        ...prevState.errors,
                                        Email: response.data.message
                                    }
                                }));

                            if (response.data.indexOf("Your support ID") >= 0) {
                                toast.error(`The requested URL was rejected. Please try again later.`);
                            }

                            if (response.data.data === "User is locked") {
                                this.props.history.push({
                                    pathname: "/Error",
                                    state: { errorMessage: 'Your account has been locked. Please contact ' }
                                });
                            }

                            return false;
                        }

                        toast.success(`OTP successfully sent to ${this.state.data.Email}`);
                        this.props.history.push({
                            pathname: "/Verify",
                            search: `?ReturnUrl=${this.state.returnUrl}`,
                            state: {
                                email: this.state.data.Email,
                                userProfileId: 0,
                                clientId: this.state.clientId,
                                clientName: this.state.clientName,
                                returnUrl: this.state.returnUrl
                            }
                        });
                    }).catch((error) => {
                        //console.log(error);
                    });
                });
        });     

    }
    isValidEmail(val) {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {

            //toast.error("Invalid email address entered");
            return false;
        }
        return true;
    }
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <span className="header-subheader">Create Account</span>
                                    <h1>Enter your email address</h1>
                                </div>
                                <form>
                                    <div className="container-content">
                                        <div className="info-wrapper">
                                            <div className="form-row">
                                                {this.renderInput("Email", "Email", "info col-lg-12", "text", true)}
                                            </div>
                                            <div className="form-row">
                                                <span className="col-lg-12">You will be sent a one-time authentication code to the email address entered.</span>
                                            </div>
                                        </div>
                                        <div className="container-buttons" style={{
                                            justifyContent: 'space-between'
                                        }} >
                                            <Link to={`/login?ReturnUrl=${this.state.returnUrl}`} >
                                                <button type="button" className="btn cancel">Cancel</button>
                                            </Link>
                                            <button   className="btn primary action" onClick={this.handleSubmit}>Next</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="container-sm login-container browser-warning">
                            <div><img alt="icon exclamation mark" aria-label="icon alert exclamation mark" src="images/icon-exclamation-circle-blue.svg"/></div>
                            <div>Note: If you experiencing issues with login or getting ReCAPTCHA error, please follow below steps to troubleshoot, or email <a href="mailto:NIAIDOTPTechSupport@mail.nih.gov" alt="email OTP admin">OTP Admin</a>.
                                <ol>
                                    <li>Please clear browser cache and try again to login, or switch to a different browser;</li>
                                    <li>If issue still persists, then it might be related to your browser version. You need to update browser version in order to proceed;</li>
                                    <li>Or you can use different laptop/phone to create an account.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}