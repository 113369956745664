import React, { Component } from "react";


export class BackButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            returnUrl: ""
        };
    }

    async componentDidMount() {

        //if (!this.props.history.location.key) {
        //    this.props.history.push({
        //        pathname: "/Error",
        //        state: { errorMessage: 'Invalid URL Access' }
        //    });
        //    return;
        //}

        //function preventBack() { window.history.forward(); }

        //setTimeout("preventBack()", 0);

        //window.history.pushState(null, document.title, "/");
        //window.addEventListener('popstate', function (event) {
        //    window.history.pushState(null, document.title, "/error/");
        //    window.onpopstate = function (event) {
        //        window.history.go(1);
        //    };
        //});

    }
    render() {
        return (
            <div>

            </div>
        );
    }

}