import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import Joi from 'joi-browser';
import AuthService from '../services/authServices';
import userService from '../services/userServices';
import Form from "./common/form";
import packageJson from '../../package.json';

export class Home extends Form {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        var reCAPTCHASite = process.env.REACT_APP_RECAPTCHASITE;

        this.state = {
            data: {
                inputEmail: ""
            },
            errors: {
                inputEmail: ""
            },
            pageEditMode: true,
            _reCAPTCHA_site: reCAPTCHASite,
            _reCAPTCHA_site_key: "",
            _reCAPTCHA_site_action: "Login",
            _reCAPTCHA_token: "",
            clientId: "eRDS",
            clientName: "",
            returnUrl: ""
        }

        this.schema = {
            inputEmail: Joi.string().trim().max(100).required().error(() => { return { message: "Email required." }; })
        };
    }

    async componentDidMount() {
        this.getApplicationName();
        this.checkBuild();
        const search = this.props.location.search;
        const returnUrl = encodeURIComponent(new URLSearchParams(search).get('ReturnUrl'));
        this.setState({ returnUrl: returnUrl });

        var captchaKey = await AuthService.getCaptchaKey();
        this.setState({ _reCAPTCHA_site_key: captchaKey.data });
        const script = document.createElement("script");
        script.src = `${this.state._reCAPTCHA_site}=${this.state._reCAPTCHA_site_key}`;
        script.addEventListener("load", this.handleLoaded);
        document.body.appendChild(script);
    }

    isValidEmail(val) {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {

            //toast.error("Invalid email address entered");
            return false;
        }
        return true;
    }

    getApplicationName() {
        //alert('getting applicationname from Home.js');
        const search = this.props.location.search;
        const returnUrl = new URLSearchParams(search).get('ReturnUrl');
        var enCodedreturnUrl = decodeURIComponent(returnUrl);

        var isClientExist = enCodedreturnUrl.indexOf('client_id') >= 0 || enCodedreturnUrl.indexOf('local_test');
        var clientId = "";
        //isClientExist = 1;
        if (isClientExist) {
            var clientstring = enCodedreturnUrl.substring(enCodedreturnUrl.indexOf('client_id'));
            clientId = clientstring.substring(clientstring.indexOf('=') + 1, clientstring.indexOf('&'));
        } else {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid Client' }
            });
        }
        //clientId = "erds.user";
        if (clientId) {
            AuthService.getApplicationName(clientId).then((response) => {
                //console.log("getApplicationName Response", response);
                if (response.data === 'ERR') {
                    this.props.history.push({
                        pathname: "/Error",
                        state: { errorMessage: 'Invalid Client' }
                    });
                }
                this.setState({ clientId: clientId, clientName: response.data });
            }).catch(error => {
                this.props.history.push({
                    pathname: "/Error",
                    state: { errorMessage: 'Invalid URL Access, please access through client.' }
                });
            });
        } else {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid Client' }
            });
        }
        
    }

    checkBuild() {
        try {
            let buildstamp = localStorage.getItem('buildstamp');
            // console.log('local buildstamp = ', buildstamp);
            // console.log('tfs buildstamp = ', packageJson.buildstamp);
            if (buildstamp != packageJson.buildstamp) {
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        // Delete all the cache files
                        names.forEach(name => {
                            caches.delete(name);
                        })
                    });

                    // Makes sure the page reloads. Changes are only visible after you refresh.
                    window.location.reload(true);
                }

                localStorage.clear();
                localStorage.setItem('buildstamp', packageJson.buildstamp);
            };
        } catch (ex) {
            //console.log("Error happened in CheckBuild");
        }
    }

    async doSubmit(event) {
        //console.log("submitting.....");

        var isEmail = this.isValidEmail(this.state.data.inputEmail);
        if (!isEmail) {
            this.setState(prevState => (
                {
                    errors: {
                        ...prevState.errors,
                        inputEmail: "Invalid email format."
                    }
                }));
            return false;
        }
        let isValidUser = true;
        await userService.getUserToVerifyByEmail(this.state.data.inputEmail).then(response => {
            //console.log(response);
            if (response.status === 204) {
                //console.log("User not exist");
                isValidUser = false;
                this.setState(prevState => (
                    {
                        errors: {
                            ...prevState.errors,
                            inputEmail: "Email not registered, create account before proceeding."
                        }
                    }));
            }
            else {
                if (response.data.isAccountLocked) {
                    isValidUser = false;
                    this.setState(prevState => (
                        {
                            errors: {
                                ...prevState.errors,
                                inputEmail: "Your account has been locked."
                            }
                        }));
                }
            }

        })
            .catch(error => {
                //console.log(error);
                isValidUser = false;
            });

        if (!isValidUser) return false;

        //console.log("proceed now");
        this.props.history.push({
            pathname: `/authenticate/${this.state.data.inputEmail}`,
            state: {
                clientId: this.state.clientId,
                clientName: this.state.clientName,
                returnUrl: this.state.returnUrl
            }
        });                   
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Sign in</h1>
                                    <div className="header-subheader">to continue to {this.state.clientName} </div>
                                    <div className="instructions">If you are an existing user, enter registered email address below and click Next.  If you are not an existing user, click Create Account.</div>
                                </div>
                                <form>
                                    <div className="container-content">
                                        <div className="info-wrapper">
                                            <div className="form-row">
                                                {this.renderInput("inputEmail", "Email", "info col-lg-12", "text", true)}
                                            </div>
                                            <div className="form-row">
                                                <span className="col-lg-12">Having trouble logging in? <a href="mailto:NIAIDOTPTechSupport@mail.nih.gov">Contact support</a></span>
                                            </div>
                                        </div>
                                        <div className="container-buttons" style={{
                                            justifyContent: 'space-between'
                                        }} >
                                            <Link to={
                                                {
                                                    pathname: `/EnterEmail`,
                                                    state: {
                                                        clientId: this.state.clientId,
                                                        clientName: this.state.clientName,
                                                        returnUrl: this.state.returnUrl
                                                    }

                                                }} >
                                                <button type="button" className="btn secondary">Create Account</button>
                                            </Link>
                                            {/*<Link to={`/authenticate/${this.state.data.inputEmail}?ReturnUrl=${this.state.returnUrl}`}>*/}

                                            <button className="btn primary action" onClick={this.handleSubmit} >Next</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="container-sm disclaimer-container">
                            <span>
                                You are accessing a U.S. Government information system which includes this computer, network, and all attached devices. This system is for Government-authorized use only. Unauthorized use of this system may result in disciplinary action and civil and criminal penalties. System users have no expectation of privacy regarding any communications or data processed by this system. Personally identifiable information (PII) entered into the OTP is used for NIH business processes. PII is information that is personal in nature that may be used to identify an individual. When a user account is established, PII is requested about users in the roles of applicants, awardees or the institutional organization staff/key personnel. For more information about NIH privacy notice please visit: <a className="disclaimer-link" href="https://www.nih.gov/web-policies-notices" target="_blank">NIH Web Policies and Notices</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;