import React, { Component } from 'react';
import { Redirect, Link, useHistory } from "react-router-dom";
import AuthService from '../services/authServices';
import { datadogRum } from '@datadog/browser-rum';
import { toast } from "react-toastify";
import UserServices from "../services/userServices";

export class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnUrl: "",
            userProfileId: this.props.location.state?.userProfileId == null ? 0 : props.location.state?.userProfileId,
            email: this.props.location.state?.email == null ? "" : this.props.location.state?.email,
            isAdmin: this.props.location.state?.isAdmin == null ? false : this.props.location.state?.isAdmin,
            isNameRequired: this.props.location.state?.isNameRequired == null ? false : this.props.location.state?.isNameRequired,
            clientId: this.props.location.state?.clientId,
            clientName: this.props.location.state?.clientName,
            showAddPhone: this.props.location.state?.showAddPhone == null ? false : this.props.location.state?.showAddPhone,
            sucessMessage: this.props.location.state?.sucessMessage == null ? "You are now logged in" : this.props.location.state?.sucessMessage,
            phoneNumber: this.props.location.state?.phoneNumber,
            isVerifiedThroughPhone: this.props.location.state?.isVerifiedThroughPhone
        }
        //this.getApplicationName = this.getApplicationName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }

        const search = this.props.location.search;
        const returnUrl = encodeURIComponent(new URLSearchParams(search).get('ReturnUrl'));
        var enCodedreturnUrl = decodeURIComponent(returnUrl);
        this.setState({ returnUrl: returnUrl });

        if (this.state.isNameRequired) {
            this.props.history.push({
                pathname: "/AccountCreate",
                search: `?ReturnUrl=${returnUrl}`,
                state: {
                    email: this.state.email,
                    userProfileId: this.state.userProfileId,
                    clientId: this.state.clientId,
                    clientName: this.state.clientName,
                    isNewUser: false,
                    isAdmin: this.state.isAdmin,
                    isNameRequired: this.state.isNameRequired
                }
            });
        }

        
        if (UserServices.haveIdSrvSession(document.cookie)) {
            datadogRum.setUser({
                id: this.state.userProfileId,
                name: this.state.email,
                email: this.state.email,
                plan: 'premium'
            });
        }
    }
    //getApplicationName(returnUrl) {
    //    console.log("getApplicationName", returnUrl);
    //    //return;
    //    var isClientExist = returnUrl.indexOf('client_id') >= 0;
    //    var clientId = "eRDS";
    //    if (isClientExist) {
            
    //        var clientstring = returnUrl.substring(returnUrl.indexOf('client_id'));
    //        console.log(clientstring);
    //        clientId = clientstring.substring(clientstring.indexOf('=') + 1, clientstring.indexOf('&'));
    //    } else {
    //        this.props.history.push({
    //            pathname: "/Error",
    //            state: { errorMessage: 'Invalid Client' }
    //        });
    //    }
    //    console.log(`ClientId = ${clientId}`);
    //    //return;
    //    var data = AuthService.getApplicationName(clientId).then((response) => {
    //        if (response.data === 'ERR') {
    //            this.props.history.push({
    //                pathname: "/Error",
    //                state: { errorMessage: 'Invalid Client' }
    //            });
    //        }
    //        this.setState({ clientId: clientId, clientName: response.data });
    //        console.log(`ClientName = ${response.data}`);
    //    });
    //}

    handleSubmit(event) {
        //var returnUrlstr = JSON.stringify(this.state.returnUrl);
        //var returnUrlEncoded = encodeURI(this.state.returnUrl);
        //console.log(returnUrlEncoded);
        event.preventDefault();
        var request = {
            ReturnUrl: this.state.returnUrl,
            ClientId: this.state.clientId,
            LoginEmail: this.state.email
        };
        var data = AuthService.redirectToApplication(request).then((response) => {
            //console.log("Response:",response);
            
            if (response.data === 'ERR') {
                //console.log("ERR in Success while redirecting");
                this.props.history.push({
                    pathname: "/Error",
                    state: { errorMessage: response.message }
                });
            }
            var authorizeEndPoint = response.data;
            //console.log("endpoint data", authorizeEndPoint);
            const redirectUrl = authorizeEndPoint.redirectUrl;
            //const returnUrl = authorizeEndPoint.returnUrl;
            //console.log("redirect URL", redirectUrl);

            //return;
            //console.log("Hash", UrlService.getHashFromUrl(redirectUri));
            ////return;
            //window.location = returnUrl;
            //var authResponse = AuthService.redirectToAuthEndPoint(returnUrl).then((response) => {
            //    console.log(response);
            //});
            //window.location.replace(redirectUri);
            //window.location.replace(returnUrl


            //let stateObj = { foo: "bar" };
            //const history = useHistory();
            //history.replaceState(stateObj, "page 3", "bar2.html");

            window.location.href=redirectUrl;
        });
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Success!</h1>
                                    {/* TODO message needs to be updated */}
                                    <span className="header-subheader">{this.state.sucessMessage}</span>
                                </div>
                                {this.state.showAddPhone && (
                                    <div className="container-content" >
                                         <div className="admin-container add-phone">
                                             <div>
                                                <Link to={{
                                                    pathname: `/VerifyPhone`,
                                                    state: {
                                                        addPhone: true,
                                                        email: this.state.email,
                                                        returnUrl: this.state.returnUrl,
                                                        clientId: this.state.clientId,
                                                        clientName: this.state.clientName,
                                                        userProfileId: this.state.userProfileId,
                                                        phoneNumber: this.state.phoneNumber
                                                    }
                                                }} >
                                                     <div className="client-profile">
                                                         <div>
                                                             <img src="/images/icon-phone-green.png" alt="user profile icon" />
                                                             <span>Add Phone</span>
                                                         </div>
                                                     </div>
                                                 </Link>
                                                 <span>
                                                     <span>Click <strong>Add Phone</strong> button to add your mobile number to your account for a faster, more secure login via SMS Text Message.</span><br />
                                                     <small className="rates">Note: Data rates may apply.</small>
                                                 </span>

                                             </div>
                                         </div>
                                    </div>
                                )}
                                
                                
                                <Link to='/home'>
                                    <div>
                                        <button className="btn primary action full-size" id="success-button" onClick={this.handleSubmit}>Continue to {this.state.clientName}</button>
                                    </div>
                                </Link>

                                
                                
                                <div className="container-buttons" style={{
                                    justifyContent: 'space-between',
                                    marginTop: '30px'
                                }} >
                                    {/* TODO update link to be dymanic account page, set to prepopulate and edit */}
                                    
                                    {!this.state.isVerifiedThroughPhone && (
                                        <Link to={{
                                            pathname: `/AccountCreate`,
                                            search: `?ReturnUrl=${this.state.returnUrl}`,
                                            state: {
                                                email: this.state.email,
                                                userProfileId: this.state.userProfileId,
                                                clientId: this.state.clientId,
                                                clientName: this.state.clientName,
                                                isNewUser: false,
                                                isAdmin: this.state.isAdmin,
                                                isNameRequired: this.state.isNameRequired
                                            }
                                        }} >
                                            <button className="btn secondary">Edit Account</button>
                                        </Link>
                                        
                                        )

                                    }

                                    {this.state.isVerifiedThroughPhone && (
                                        <Link to={{
                                            pathname: `/Verify`,
                                            search: `?ReturnUrl=${this.state.returnUrl}`,
                                            state: {
                                                email: this.state.email,
                                                userProfileId: this.state.userProfileId,
                                                isSendSms: false,
                                                clientId: this.state.clientId,
                                                clientName: this.state.clientName,
                                                isVerificationForAccountUpdate: true,
                                                isAdmin: this.state.isAdmin,
                                                previousScreen: "Success"
                                            }
                                        }} >
                                            <button className="btn secondary">Edit Account</button>
                                        </Link>

                                    )

                                    }
                                    <div className="info col-lg-8" style={{display:'none'}}>
                                        <div className="text-confirmation">You will be prompted to verify your phone number after saving</div>
                                    </div>
                                </div>
                                {this.state.isAdmin && (<div className="container-content" >
                                    <div className="admin-container">
                                        <div>Admin Functions</div>
                                        <div>
                                            {/* TODO update admin page links */}
                                            
                                            <Link to={{
                                                    pathname: `/UserSearch`,
                                                    state: {
                                                        clientId: this.state.clientId,
                                                        email: this.state.email,
                                                        returnUrl: this.state.returnUrl
                                                    }
                                                }} >
                                                <div className="user-profile">
                                                    <div>
                                                        <img src="/images/icon-user-brown.png" alt="user profile icon" />
                                                        <span>Manage User Profile</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            
                                            <Link to={{
                                                    pathname: `/ClientSearch`,
                                                    state: {
                                                        clientId: this.state.clientId,
                                                        email: this.state.email,
                                                        returnUrl: this.state.returnUrl
                                                    }
                                                }} >
                                                <div className="client-profile">
                                                    <div>
                                                        <img src="/images/icon-client-green.png" alt="client profile icon" />
                                                        <span>Manage Client Profile</span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to={{
                                                pathname: `/ViewLogs`,
                                                state: {
                                                    clientId: this.state.clientId,
                                                    email: this.state.email,
                                                    returnUrl: this.state.returnUrl
                                                }
                                            }} >
                                                <div className="view-logs">
                                                    <div>
                                                        <img src="/images/icon-logs-blue.png" alt="logs icon" />
                                                        <span>View Logs<br />&nbsp;</span>
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link to={{
                                                pathname: `/Settings`,
                                                state: {
                                                    clientId: this.state.clientId,
                                                    email: this.state.email,
                                                    returnUrl: this.state.returnUrl
                                                }
                                            }} >
                                                <div className="settings">
                                                    <div>
                                                        <img src="/images/icon-settings-brown.png" alt="settings icon" />
                                                        <span>OTP Settings<br />&nbsp;</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>)}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}