import React, { Component } from "react";
import authService from '../services/authServices';
import { datadogRum } from '@datadog/browser-rum';
import { toast } from "react-toastify";

export class Logout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            returnUrl: "",
            showLink:false
        };
    }

    async componentDidMount() {

        //if (!this.props.history.location.key) {
        //    this.props.history.push({
        //        pathname: "/Error",
        //        state: { errorMessage: 'Invalid URL Access' }
        //    });
        //    return;
        //}

        const search = this.props.location.search;
        const logoutId = new URLSearchParams(search).get('logoutId');

        //console.log("logoutId:", logoutId);
        //var enCodedreturnUrl = decodeURIComponent(returnUrl);

        var request= {
            logoutId
        }

        authService.logout(request).then((response) => {
            //console.log(response);
            datadogRum.removeUser();
            //datadogRum.removeGlobalContextProperty("user");

            let { data } = response;
            let returnUrl = data.data;

            this.setState({ returnUrl });
            if (returnUrl) {
                this.setState({
                    showLink: true
                });
            }

        }).catch((error) => {

            toast.error("Error in logout");
            //console.log(error);
            this.setState({
                showLink: false
            });
        });
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Logout Success</h1>
                                </div>
                                <div className="container-content">
                                    <span className="header-subheader">You have now logged out of the application.</span>
                                    {this.state.showLink && <span>Click <a href={this.state.returnUrl}>here</a> to return to the application.</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}