import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



import { toast } from "react-toastify";

export class VerifyPhone extends Component {
    static displayName = VerifyPhone.name;

    constructor(props) {
        super(props);
        this.state = {
            data: {
                phoneNumber:this.props.location.state?.phoneNumber
            },

            email: this.props.location.state?.email !== undefined ? this.props.location.state?.email : "",
            isAddPhone: this.props.location.state?.addPhone !== undefined ? this.props.location.state?.addPhone : false,
            userProfileId: this.props.location.state?.userProfileId == null ? 0 : props.location.state?.userProfileId,
            clientId: this.props.location.state?.clientId,
            clientName: this.props.location.state?.clientName,
            returnUrl: this.props.location.state?.returnUrl
        };

        this.setPhone = this.setPhone.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    setPhone(val) {
        const data = Object.assign({}, this.state.data);
        data['phoneNumber'] = val;
        this.setState({ data });
        //console.log(val);
    }

    async componentDidMount() {

        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        //console.log("Verify Phone Handle Submit");

        if (!this.state.data.phoneNumber) {
            toast.error("Please enter phone number before proceeding");
            return false;
        }
        if (this.state.data.phoneNumber.length < 5) {
            toast.error("Please enter a valid phone number before proceeding");
            return false;
        }

        let phoneNumber = this.state.data.phoneNumber;
        if (!phoneNumber.startsWith("+"))
            phoneNumber = `+${phoneNumber}`;

        //console.log("Proceed with Phone number verification");
        this.props.history.push({
            pathname: "/Verify",
            search: `?ReturnUrl=${this.state.returnUrl}`,
            state: {
                email: this.state.email,
                userProfileId: this.state.userProfileId,
                clientId: this.state.clientId,
                clientName: this.state.clientName,
                isSendSms: true,
                phoneNumber: phoneNumber,
                previousScreen:"VerifyPhone"
            }
        });
    }

    CancelSubmit = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: "/Success",
            search: `?ReturnUrl=${this.state.returnUrl}`,
            state: {
                email: this.state.email,
                userProfileId: this.state.userProfileId,
                clientId: this.state.clientId,
                clientName: this.state.clientName,
                showAddPhone: true,
                sucessMessage: null,
                phoneNumber: this.state.data.phoneNumber
            }
        });
    }
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <span className="header-subheader">Update Account</span>
                                    <h1>{this.state.isAddPhone? "Add":"Verify"} your phone number</h1>
                                </div>
                                <form>
                                    <div className="container-content">

                                        <div className="verify-container phone">
                                            <div className="form-row" id="verify-phone-number">
                                                <div className="info col-lg-12">
                                                    <label htmlFor="phoneNumber">Mobile Phone Number </label>
                                                    <PhoneInput
                                                        name="phoneNumber"
                                                        id="phoneNumber"
                                                        country={'us'}
                                                        defaultValue={this.state.data.phoneNumber}
                                                        value={this.state.data.phoneNumber}
                                                        inputClass="MuiInputBase-input MuiInput-input"
                                                        onChange={(val) => this.setPhone(val)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-lg-12">
                                                    <div>You will be sent a one-time authentication code to the phone number entered as an SMS text message.</div>
                                                    <small className="rates">Data rates may apply.</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-buttons" style={{ justifyContent: 'space-between' }} >
                                            <button className="btn cancel" onClick={this.CancelSubmit}>Cancel</button>

                                            <button className="btn primary action" onClick={this.handleSubmit}>Next</button>
                                            
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};