import React from 'react'
import EnhancedTableHead from "../common/enhancedTableHead";
import { Link } from '@material-ui/core';
import LogService from "../../services/logService";
import PaginationBar from "../common/pagination";
import sortingServices from '../../services/sortingServices';
import { toast } from "react-toastify";

export function getFileLogs(props) {
    const headCells = [
        { id: 'date', align: 'center', disablePadding: true, label: 'Date', sort: true, width: '20%' },
        { id: 'fileName', align: 'left', disablePadding: false, label: 'File', sort: true, width: '60%' },
        { id: 'action', align: 'center', disablePadding: false, label: 'Action', sort: false, width: '20%' }
    ];
    const trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
    const wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }

    const getFileLogsH = async () => {
        try {
            props.setState({ fileLoading: true });
            const fromDate = props.state.data["fileFromDate"];
            const toDate = props.state.data["fileToDate"];
            let res = await LogService.getFileLogs({ from: fromDate, to: toDate });
            let data = res.data;
            props.setState({ filePage: 0 });
            
            props.setState({ fileFind: "", fileRows: data, fileUiRows: data, fileLoading: false });
        }
        catch (ex) {
            props.setState({ fileLoading: false });
            toast.error("FETCH FILE LOGS FAILED");
        }
    }

    const getFieLogText = async (event) => {
        try {
            props.setState({ fileLoading: true });
            var fileName = event.target.name;
            let res = await LogService.getFileLogText({ fileName: fileName });
            let data = res.data;
            props.setState({ fileLoading: false });
            OpenWindowWithPost("",
                "width=1200,height=845,left=100,top=100,resizable=yes,scrollbars=yes",
                fileName, data);
        }
        catch (ex) {
            props.setState({ fileLoading: false });
            toast.error("FETCH FILE LOG TEXT FAILED");
        }
    }

    const downloadFieLog = async (event) => {
        try {
            props.setState({ fileLoading: true });
            var fileName = event.target.id;

            if (!fileName || fileName.length === 0) {
                props.setState({ fileLoading: false });
                return false;
            }
            props.handleDownloadPost(`api/Logs/downloadFileLog`, { fileName: fileName });
            props.setState({ fileLoading: false });
            
        }
        catch (ex) {
            props.setState({ fileLoading: false });
            toast.error("Download file failed");
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = props.state.fileOrderBy === property && props.state.fileOrder === 'asc';
        props.setState({ fileOrder: isAsc ? 'desc' : 'asc', fileOrderBy: property });
    };

    const handleChangePage = (event, newPage) => {
        props.setState({ filePage: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        props.setState({ fileRowsPerPage: parseInt(event.target.value, 10), filePage: 0 });
    };

    let sortedRows = sortingServices.stableSort(props.state.fileUiRows, sortingServices.getComparator(props.state.fileOrder, props.state.fileOrderBy));

    const OpenWindowWithPost = (url, windowoption, name, text) => {
        var win = window.open(url, name, windowoption);
        win.document.body.innerHTML = "";
        win.document.write("<html><header><title>" + name + "</title></header><body>" + text + "</body></html>");
    }

    return (
        <div id="client-scope" role="tabpanel" aria-label="client-scope-tab">
            <div className="tile-section form-section">
                {props.state.fileLoading && props.loader}
                <form onSubmit={e => e.preventDefault()}>
                    <div className="container-content sql-search-container">
                        <div className="logsearch-container">
                            <div className="info-wrapper">
                                <div className="form-row">
                                    {props.renderInput("fileFromDate", "From Date", "info col-lg-5", "text", true)}
                                    {props.renderInput("fileToDate", "To Date", "info col-lg-5", "text", true)}
                                    <div component="button">
                                        <button className="btn btn-primary search" disabled={props.validate() != null} onClick={getFileLogsH}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="logresults-container sql-table-container">
                            <div className="info-wrapper">
                                <table className="MuiTable-root" style={{ width: "100%" }}>
                                    <EnhancedTableHead headCells={headCells} order={props.state.fileOrder} orderBy={props.state.fileOrderBy} onRequestSort={handleRequestSort} rowCount={props.state.fileRows.length} />
                                    <tbody className="MuiTableBody-root">
                                        {(props.state.fileRowsPerPage > 0 ? sortedRows.slice(props.state.filePage * props.state.fileRowsPerPage, props.state.filePage * props.state.fileRowsPerPage + props.state.fileRowsPerPage) : sortedRows).map((row, index) => (
                                            <React.Fragment key={row.fileName}>
                                                <tr className="MuiTableRow-root MuiTableRow-hover" style={trMain(index)}>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft"><span className="ml-3">{row.dateStr}</span></td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                                        <Link onClick={getFieLogText} name={row.fileName} style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}>
                                                            {row.fileName}
                                                        </Link>
                                                    </td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                                        <div component="button" class="d-flex justify-content-center" id={row.fileName} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" id={row.fileName} width="16" height="16" fill="currentColor" class="bi bi-download"
                                                                style={{cursor: "pointer"}} viewBox="0 0 16 16" onClick={downloadFieLog}>
                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="MuiTableCell-root MuiTablePagination-root" colSpan="6">
                                                <PaginationBar page={props.state.filePage} rowsPerPage={props.state.fileRowsPerPage} rows={sortedRows}
                                                    handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default {
    getFileLogs: getFileLogs
}