import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import TextArea from "./textArea";
import axios from 'axios';

class Form extends Component {
    state = {
        data: {},
        errors: {},
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };

        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    };

    validate = () => {
        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.data, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;

        return errors;
    };

    handleDownload = (url, fileId, filename) => {
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
            onDownloadProgress(progress) {
                // console.log('download progress:', progress);
            }
        }).then((res) => {
            this.handleDownloadSuccess(res, fileId, filename);
        })
            .catch(error => {
                this.handleDownloadError(error, fileId);
            });
    }

    handleDownloadPost = (url, data) => {
        axios({
            url: url,
            method: 'POST',
            data: data,
            responseType: 'blob', // Important
            onDownloadProgress(progress) {
                // console.log('download progress:', progress);
            }
        }).then((res) => {
            this.handleDownloadSuccess(res, data.fieldId, data.fileName);
        })
            .catch(error => {
                this.handleDownloadError(error, data.fieldId);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        //console.log(errors);
        if (errors) return;

        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = Object.assign({}, this.state.errors);
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = Object.assign({}, this.state.data);
        data[input.name] = input.value;
        this.setState({ data, errors });
    };

    handleDateChange = (name, value) => {
        const input = { currentTarget: { name, value } }
        this.handleChange(input);
    }

    renderButton(label) {
        return (
            <button disabled={this.validate()} className="btn btn-primary">
                {label}
            </button>
        );
    }

    renderSelect(name, label, options, classSize, isRequired = false, disabled = undefined) {
        const { data, errors, pageEditMode } = this.state;
        const isDisabled = disabled != undefined ? disabled : !pageEditMode;
        return <Select
            name={name}
            value={data[name]}
            label={label}
            classSize={classSize}
            readOnly={!pageEditMode}
            disabled={isDisabled}
            options={options}
            onChange={this.handleChange}
            error={errors[name]}
            isRequired={isRequired}
        />;
    }

    renderInput(name, label, inputSize, type = "text", required = true, placeholder, disabled = undefined) {
        const { data, errors, pageEditMode } = this.state;
        const isDisabled = disabled != undefined ? disabled : !pageEditMode;
        return (
            <Input
                inputSize={inputSize}
                type={type}
                name={name}
                value={data[name]}
                label={label}
                readOnly={!pageEditMode}
                disabled={isDisabled}
                required={required}
                error={errors[name]}
                onChange={this.handleChange}
                placeholder={placeholder}
            />
        );
    }

    renderTextArea(name, label, maxLength) {
        const { data, errors, pageEditMode } = this.state;
        return (
            <TextArea
                name={name}
                value={data[name]}
                maxLength={maxLength}
                label={label}
                readOnly={!pageEditMode}
                disabled={!pageEditMode}
                error={errors[name]}
                onChange={this.handleChange}
            />
        );
    }
}

export default Form;
