import http from "./httpService";

export async function getLogs(body) {
    return http.post(`api/Logs/getLogs`, body);
}

export async function getFileLogs(body) {
    return http.post(`api/Logs/getFileLogs`, body);
}

export async function getFileLogText(body) {
    return http.post(`api/Logs/getFileLogText`, body);
}

export async function downloadFileLog(body) {
    return http.post(`api/Logs/downloadFileLog`, body);
}

export default {
    getLogs: getLogs,
    getFileLogs: getFileLogs,
    getFileLogText: getFileLogText,
    downloadFileLog: downloadFileLog
}