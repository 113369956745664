import React, { useState, useEffect, Fragment } from 'react';
import { LinkMenu } from './LinkMenu';

import EnhancedTableHead from "../common/enhancedTableHead";
import PaginationBar from "../common/pagination";
import userServices from '../../services/userServices';
import sortingServices from '../../services/sortingServices';

import { Redirect, Link } from "react-router-dom";
import { toast } from "react-toastify";


//Header
const headCells = [
    { id: 'applicationName', align: 'left', label: 'Application', sort: true, width: '15%' },
    { id: 'email', align: 'left', label: 'Email', sort: true, width: '15%' },
    { id: 'firstName', align: 'left', label: 'First Name', sort: true, width: '15%' },
    { id: 'lastName', align: 'left', label: 'Last Name', sort: true, width: '10%' },
    { id: 'phone', align: 'left', label: 'Phone Number', sort: true, width: '15%' },
    { id: 'company', align: 'left', label: 'Company', sort: true, width: '15%' },
    { id: 'isAccountLocked', align: 'left', label: 'Is Locked?', sort: true, width: '10%' },
    { id: 'action', align: 'center', label: 'Action', sort: false, width: '5%' }
];


export function UserSearch(props) {
    const [searchText, setSearchText] = useState([]);

    const [loading, setLoading] = useState(false);

    const [uiRows, setUiRows] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    
    //let emptyRows = rowsPerPage - Math.min(rowsPerPage, uiRows.length - page * rowsPerPage);
    let sortedRows = [];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
            if (!props.history.location.key) {
                //console.log("REDIRECT TO ERROR PAGE");
                props.history.push({
                    pathname: "/Error",
                    state: { errorMessage: 'Invalid URL Access' }
                });
            }

        },
        []);

    //const handleEditProfile = (event, row) => {
    //    console.log("handle edit profile:", row);
    //};

    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleSearchInput= e => {
        setSearchText(e.target.value);
    }

    const handleSearch=(event)=> {
        event.preventDefault();
        //console.log("user clicked search - todo fetch user data from backend");
        setLoading(true);
        userServices.searchUsers(searchText).then((response) => {
            //console.log(response);

            setPage(0);
            let data = response.data;
            setUiRows(data);
            setLoading(false);

        }).catch((error) => {

            toast.error("Error in userSearch");
            //console.log(error);
        });

    }


    //Generic table stuff
    const loader = (<div className="loader" style={{ position: "fixed", top: "30%", left: "45%" }}>Loading...</div>);
    sortedRows = sortingServices.stableSort(uiRows, sortingServices.getComparator(order, orderBy));
    const trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
    const wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }
    
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-md userprofile-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Search User Profile</h1>
                                    
                                    <LinkMenu {...props.location.state} />
                                </div>

                                <form>
                                    <div className="container-content sql-search-container">
                                        <div className="search-container">
                                            <div className="info-wrapper">
                                                <div className="form-row">
                                                    <div className="info col-lg-8">
                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                            {/* {this.renderInput("searchText", "Search User", "info col-lg-8", "text", true)}*/}
                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="clientName" id="clientName-label">User Name</label>
                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                <input aria-invalid="false" id="searchText" name="searchText" required="" value={searchText} onChange={handleSearchInput} type="text" className="MuiInputBase-input MuiInput-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary search" onClick={handleSearch}>Search</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="results-container sql-table-container">
                                            <div className="info-wrapper">
                                                {loading && loader}
                                                <table className="MuiTable-root" style={{ width: "100%" }}>
                                                    <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={uiRows.length} />
                                                    <tbody className="MuiTableBody-root">
                                                        {(rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows).map((row, index) => (
                                                            <React.Fragment key={row.userProfileId}>
                                                                <tr className="MuiTableRow-root MuiTableRow-hover" style={trMain(index)}>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={wordWrap} data-label='Application'><div>{row.applicationName}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Email'><div>{row.email}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='First Name'><div>{row.firstName}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Last Name'><div>{row.lastName}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Phone'><div>{row.phone}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Company'><div>{row.company}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Is Locked'><div>{row.isAccountLocked?"Yes":"No"}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter" data-label='Action'>
                                                                        <span className="sql-table-actions" >
                                                                            <span className="action-icon">
                                                                                <Link to={{
                                                                                    pathname: `/userprofile`,
                                                                                    state: {
                                                                                        userProfileId: row.userProfileId,
                                                                                        ...props.location.state
                                                                                    }
                                                                                }}>
                                                                                    Edit
                                                                                </Link>
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className="MuiTableCell-root MuiTablePagination-root" colSpan="8">
                                                                <PaginationBar page={page} rowsPerPage={rowsPerPage} rows={sortedRows}
                                                                    handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} />
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    
}
