import React from 'react';
import { TextareaAutosize } from '@material-ui/core';

const TextArea = ({ name, label, error, value, maxLength, readOnly, disabled, onChange }) => {
    return (

        <div className = "col-lg-9" >
            <div className="MuiFormControl-root MuiTextField-root">

                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor={name} id={`${name}-label`}>
                    {label}
                </label>
                <div className="MuiInputBase-root MuiInput-root MuiInputBaseControl MuiInputControl MuiInputBase-multiline MuiInput-multiline">
                    {/* <textarea aria-invalid="false" name={name} id={name} defaultValue={value} onChange={onChange}  rows="1" className="MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline" /> */}
                    <TextareaAutosize maxLength={maxLength} aria-invalid="false" name={name} id={name} defaultValue={value} readOnly={readOnly} disabled={disabled} onInput={onChange}  rows="1" className="MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline" />
                </div>
                {error && <small className="text-danger">{error}</small>}
            </div>
         </div >
    );
};

export default TextArea;