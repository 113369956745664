import React, { useState, useEffect, Fragment } from 'react';

import EnhancedTableHead from "../common/enhancedTableHead";
import PaginationBar from "../common/pagination";
import clientServices from '../../services/clientServices';
import sortingServices from '../../services/sortingServices';

import { LinkMenu } from './LinkMenu';
import { Redirect, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";



const headCells = [
    { id: 'clientName', align: 'left', label: 'Client Name', sort: true, width: '40%' },
    { id: 'clientId', align: 'left', label: 'Client Id', sort: true, width: '40%' },
    { id: 'isEnabled', align: 'center', label: 'Enabled?', sort: false, width: '10%' },
    { id: 'action', align: 'center', label: 'Action', sort: false, width: '10%' }
];

export function ClientSearch(props) {
    const [searchText, setSearchText] = useState("");

    const [loading, setLoading] = useState(false);
    const [uiRows, setUiRows] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    
    let sortedRows = [];
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
   

    useEffect(() => {
            if (!props.history.location.key) {
                props.history.push({
                    pathname: "/Error",
                    state: { errorMessage: 'Invalid URL Access' }
                });
            } else {
                searchClient();
            }

        },
        []);

    const handleEnableChange = (event, row) => {

        clientServices.toggleClientActivation(row.clientSequenceId).then((response) => {
            //console.log(response);

            const updateResult = response.data;

            if (updateResult.isSuccess) {

                toast.success(updateResult.message);
            
                const idx = uiRows.findIndex(obj => obj.clientSequenceId === row.clientSequenceId);
                //console.log("index", idx);
                const updatedRows = [...uiRows];
                updatedRows[idx].enabled = !updatedRows[idx].enabled;
                setUiRows(updatedRows);
            }

            if (!updateResult.isSuccess) {

                toast.error(updateResult.message);
            }

        }).catch((error) => {

            toast.error("Error in Enable/disable Client");
            //console.log(error);
        });



            
        
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInput = e => {
        setSearchText(e.target.value);
    }
    const searchClient=() => {
        setLoading(true);
        clientServices.searchClients(searchText).then((response) => {
            //console.log(response);

            setPage(0);
            let data = response.data;
            setUiRows(data);
            setLoading(false);

        }).catch((error) => {

            toast.error("Error in Client Search");
            //console.log(error);
        });
    }

    const handleSearch = (event) => {
        event.preventDefault();
        //console.log("user clicked search - todo fetch client data from backend");
        searchClient();
        //setLoading(true);
        //clientServices.searchClients(searchText).then((response) => {
        //    console.log(response);

        //    setPage(0);
        //    let data = response.data;
        //    setUiRows(data);
        //    setLoading(false);

        //}).catch((error) => {

        //    toast.error("Error in Client Search");
        //    console.log(error);
        //});

    }

    //Generic table stuff
    const loader = (<div className="loader" style={{ position: "fixed", top: "30%", left: "45%" }}>Loading...</div>);
    sortedRows = sortingServices.stableSort(uiRows, sortingServices.getComparator(order, orderBy));
    const trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
    const wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }

        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-md clientprofile-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Search Client Profile</h1>

                                    <LinkMenu {...props.location.state} />
                                </div>

                                <form>
                                    <div className="container-content sql-search-container">
                                        <div className="clientsearch-container">
                                            <div className="info-wrapper">
                                                <div className="form-row">
                                                    <div className="info col-lg-8">
                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="searchText" id="clientName-label">Client Name</label>
                                                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                            <input aria-invalid="false" id="searchText" name="searchText" required="" value={searchText} onChange={handleSearchInput} type="text" className="MuiInputBase-input MuiInput-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link>
                                                        <button className="btn btn-primary search" onClick={handleSearch}>Search</button>
                                                    </Link>
                                                    <Link to={{
                                                        pathname: `/ClientProfile`,
                                                        state: {
                                                            clientSequenceId: -1,
                                                            ...props.location.state
                                                        }
                                                    }}>
                                                        <button className="btn btn-primary action add">Add Client</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="clientresults-container sql-table-container">
                                            <div className="info-wrapper">
                                                {loading && loader}
                                                <table className="MuiTable-root" style={{ width: "100%" }}>
                                                    <EnhancedTableHead order={order} headCells={headCells} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={uiRows.length} />
                                                    <tbody className="MuiTableBody-root">
                                                        {(rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows).map((row, index) => (
                                                            <React.Fragment key={row.clientSequenceId}>
                                                                <tr className="MuiTableRow-root MuiTableRow-hover" style={trMain(index)}>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={wordWrap} data-label='ClientName'><div>{row.clientName}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='ClientId'><div>{row.clientId}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter" data-label='Enabled'><div>{row.enabled?'Yes':'No'}</div></td>
                                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter" data-label='Action'>
                                                                        <span className="sql-table-actions" >
                                                                            <span className="action-icon">
                                                                                <Link to={{
                                                                                    pathname: `/ClientProfile`,
                                                                                    state: {
                                                                                        clientSequenceId: row.clientSequenceId,
                                                                                        ...props.location.state
                                                                                    }
                                                                                }}>
                                                                                    Edit
                                                                                </Link>
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className="MuiTableCell-root MuiTablePagination-root" colSpan="4">
                                                                <PaginationBar page={page} rowsPerPage={rowsPerPage} rows={sortedRows}
                                                                    handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} />
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    
}
