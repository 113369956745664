import axios from "axios";
import { toast } from "react-toastify";


function clearStorage() {
    //localStorage.removeItem(ecpsExternalAccessTokenValidTo_Key);
    //localStorage.removeItem(ecpsExternalRefreshTokenValidTo_Key);
}

axios.interceptors.request.use(async function (config) {
    return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const unauthorizedError = error.response && error.response.status === 401;
    const serverError = error.response && error.response.status === 500;
    const awafError = error.response && error.response.status === 400;

    if (unauthorizedError) {
        const clientUri = error.response.headers["clienturi"];
        if (clientUri) {
            let validUrl = IsValidClinetUri(clientUri);

            if (validUrl)
                window.location = `/tokenExpired?clientUri=${validUrl}`;
        }
        else {
            window.location = '/tokenExpired';
        }
    }

    if (awafError) {
        var message = error.response.data;
        message = message.slice(56).slice(0, 120).replace("<br><br>", ' ').replace("<", '');
        toast.error(message);
        //toast.error("The requested URL was rejected. Please try again later.");
    }

    if (serverError)
        toast.error("Internal Server Error, Please contact NIAIDOTPTechSupport@mail.nih.gov");

    if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
    return Promise.reject(error);
});

async function IsValidClinetUri(uri) {
    let isValid = "";
    await axios.get(`common/isclienturivalid?uri=${uri}`)
        .then(
            response => isValid = response.data)
        .catch(
            error => alert(error));
    return isValid;

}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    cancelToken: axios.CancelToken
};
