import React, { useState, useEffect, Fragment } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

 export default function EnhancedTableHead(props) {
    const { headCells,classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="header">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        width={headCell.width}
                        align={headCell.align}
                        //padding={headCell.disablePadding ? 'none' : 'default'}

                        //padding={headCell.disablePadding ? 'none' : headCell.padding}

                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <div className={headCell.mClass}>
                            {headCell.label}

                            {headCell.sort && headCell.id !== 'action' &&
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'desc'}
                                    onClick={createSortHandler(headCell.id)}
                                >

                                    {orderBy === headCell.id ? (
                                        <span style={{ display: "none" }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            }
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    );
}