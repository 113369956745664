import http from "./httpService";

export async function getApplicationName(clientId) {
    return http.get(`account/getapplicationname/${clientId}`);
}
export async function getCountryList() {
    return http.get('common/getcountrylist');
}

export async function getCaptchaKey() {
    return http.get('common/getcaptchakey');
}

export async function createUser(user) {
    const body = { ...user }
    //console.log("User data to Save: ", body);

    return http.post("account/register", body);
}
export async function sendOtpCodeForNewUser(emailRequest) {
    //console.log("Otp send method call");
    return http.post("account/sendOtpCodeForNewUser", emailRequest)
    .catch(err => {
        return err;
    });
}

export async function resendOtpCodeForNewUser(emailRequest) {
    //console.log("Otp send method call");
    return http.post("account/resendOtpCodeForNewUser", emailRequest)
        .catch(err => {
            return err;
        });
}
export async function validateOtpCodeForNewUser(otpRequest) {
    //console.log("Otp validate method call");
    return http.post("account/verifyOtpForNewUser", otpRequest)
        .catch(err => {
            return err;
        });
}

export async function updateUser(updateRequest,email,isNewUser=false) {
    //console.log("Update User method call");
    if (!isNewUser) {
        email = Buffer.from(email).toString('base64');
        return http.post("account/updateuser/" + email, updateRequest)
            .catch(err => {
                return err;
            });
    } else {
        return http.post("account/updateNewUser", updateRequest)
            .catch(err => {
                return err;
            });
    }
    
}

export async function verifyOtp(otpRequest) {
    //console.log("Otp validate method call");
    return http.post("account/verifyOtp", otpRequest)
        .catch(err => {
            return err;
        });
}

export async function sendOtpCodeForUser(request) {
    //console.log("Otp send method call");
    return http.post("account/sendOtpCode", request)
        .catch(err => {
            return err;
        });
}

export async function getDeliveryStatus() {
    return http.get("account/GetDeliveryStatus");
}

export async function redirectToApplication(request) {
    //console.log("Redirect to application method call", request);
    //return;
    return http.post("account/redirectToApplication", request)
        .catch(err => {
            //console.log("Redirect to application method call catch", err);
            return err;
        });
}

export async function logout(logoutRequest) {
   
    return http.post(`account/logout/`, logoutRequest)
        .catch(err => {
            return err;
        });
}

export async function redirectToAuthEndPoint(request) {
    return http.get(request);
}
export default {
    getApplicationName: getApplicationName,
    getCountryList: getCountryList,
    sendOtpCodeForNewUser: sendOtpCodeForNewUser,
    resendOtpCodeForNewUser: resendOtpCodeForNewUser,
    validateOtpCodeForNewUser: validateOtpCodeForNewUser,
    getCaptchaKey: getCaptchaKey,
    updateUser: updateUser,
    sendOtpCodeForUser: sendOtpCodeForUser,
    getDeliveryStatus: getDeliveryStatus,
    verifyOtp: verifyOtp,
    redirectToApplication: redirectToApplication,
    redirectToAuthEndPoint: redirectToAuthEndPoint,
    logout: logout
};
