import React, { Component } from "react";
import Mailto from './common/mailTo';

export class ErrorPage extends Component {

     constructor(props) {
          super(props);
         this.state = {
            errorMessage: props.location.state.errorMessage == null ? "Oops, something went wrong. Please go to the application Home page and try to login again or contact" : props.location.state.errorMessage
          };
     }

     render() {
          return (
              <div>
                  <div className="content">
                      <div className="main-content centered-content static-page">
                          <div className="container-sm login-container">
                              <div className="content-container">
                                  <div className="container-header">
                                      <h1>Error</h1>
                                  </div>
                                  <div className="container-content">
                                      <span className="header-subheader">{this.state.errorMessage} <Mailto email="NIAIDOTPTechSupport@mail.nih.gov" subject="OTP Login Issue" >Admin</Mailto> </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          );
     }
}