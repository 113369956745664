import React from 'react'
import EnhancedTableHead from "../common/enhancedTableHead";
import LogService from "../../services/logService";
import PaginationBar from "../common/pagination";
import sortingServices from '../../services/sortingServices';
import { toast } from "react-toastify";

export function getDbLogs(props) {
    const headCells = [
        { id: 'date', align: 'center', disablePadding: true, label: 'Date', sort: true, width: '20%' },
        { id: 'level', align: 'center', disablePadding: false, label: 'Level', sort: true, width: '10%' },
        { id: 'logger', align: 'center', disablePadding: false, label: 'Logger', sort: true, width: '25%' },
        { id: 'method', align: 'center', disablePadding: false, label: 'Method', sort: true, width: '10%' },
        { id: 'message', align: 'center', disablePadding: false, label: 'Message', sort: true, width: '30%' },
        { id: 'action', align: 'center', disablePadding: false, label: 'Action', sort: false, width: '5%' }
    ];

    const trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
    const wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }

    const initClosedC = { transition: 'all 0.3s ease 0.3s', display: "block", height: "0", opacity: "0", overflow: 'hidden' };
    const closedC = { WebkitTransition: "all 0.3s ease-in-out 0.3s", opacity: "0" }
    const openC = { WebkitTransition: "all 0.3s ease-in-out 0.3s", opacity: "1" }

    const getLogsH = async () => {
        try {
            props.setState({ loading: true });
            const theLevel = "";
            const fromDate = props.state.data["fromDate"];
            const toDate = props.state.data["toDate"];

            let res = await LogService.getLogs({ from: fromDate, to: toDate, level: theLevel, message: props.state.data["message"] });
            let data = res.data;
            props.setState({ page: 0 });
            for (let d of data) {
                //const dtStr = dateTimeService.toDateTimeStr(d.date);
                const dtStr = d.dateStr;
                //d.dateTimeStr = dtStr;
                d.allStr = dtStr + " " + d.level + " " + d.logger + " " + d.message;
                d.allStr = d.allStr.toLowerCase();
            }
            props.setState({ find: "", rows: data, uiRows: data, loading: false });
        }
        catch (ex) {
            props.setState({ loading: false });
            toast.error("FETCH LOGS FAILED");
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = props.state.orderBy === property && props.state.order === 'asc';
        props.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    };

    const handleChangePage = (event, newPage) => {
        props.setState({ page: newPage });
    };

    const handleChangeRowsPerPage = (event) => {
        props.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    };

    const toggle = (r) => {
        var obj = document.getElementById('details_' + r.id);

        if (!r.isOpen) {
            r.isOpen = true;
            obj.style.opacity = '1';
            obj.style.height = 'auto';
        }
        else {
            r.isOpen = false;
            obj.style.opacity = '0';
            obj.style.height = '0';
        }
        props.setState({ reRender: !props.state.reRender });
    }

    const resetOpen = (row) => {
        if (props.state.rowOpened && props.state.rowOpened.row && props.state.rowOpened.row.id !== row.id) {
            props.state.rowOpened.row.isOpen = false;
            var obj = document.getElementById('details_' + props.state.rowOpened.row.id);
            if (obj) {
                obj.style.opacity = "0";
                obj.style.height = '0';
            }
        }
        props.setState({ rowOpened: { row: row, detailId: "deatails_" + row.id }, reRender: !props.state.reRender });
    }

    let sortedRows = sortingServices.stableSort(props.state.uiRows, sortingServices.getComparator(props.state.order, props.state.orderBy));
    
    return (
        <div id="client-profile" role="tabpanel" aria-label="client-profile-tab">
            <div className="tile-section form-section user-profile">
                {props.state.loading && props.loader}

                <form onSubmit={e => e.preventDefault()}>
                    <div className="container-content sql-search-container">
                        <div className="logsearch-container">
                            <div className="info-wrapper">
                                <div className="form-row">
                                    {props.renderInput("fromDate", "From Date", "info col-lg-3", "text", true, "MM/DD/YYYY hh:mm:ss AM")}
                                    {props.renderInput("toDate", "To Date", "info col-lg-3", "text", true, "MM/DD/YYYY hh:mm:ss PM")}
                                    {props.renderInput("message", "Message", "info col-lg-5", "text", true)}
                                    <div>
                                        <button className="btn btn-primary search" disabled={props.validate() != null} onClick={getLogsH}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="logresults-container sql-table-container">
                            <div className="info-wrapper">
                                <table className="MuiTable-root" style={{ width: "100%" }}>
                                    <EnhancedTableHead headCells={headCells} order={props.state.order} orderBy={props.state.orderBy} onRequestSort={handleRequestSort} rowCount={props.state.rows.length} />
                                    <tbody className="MuiTableBody-root">
                                        {(props.state.rowsPerPage > 0 ? sortedRows.slice(props.state.page * props.state.rowsPerPage, props.state.page * props.state.rowsPerPage + props.state.rowsPerPage) : sortedRows).map((row, index) => (
                                            <React.Fragment key={row.id}>
                                                <tr className="MuiTableRow-root MuiTableRow-hover" style={trMain(index)}>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft"><span className="ml-3">{row.dateStr}</span></td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">{row.level}</td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={wordWrap}>{row.logger}</td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">{row.method}</td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={wordWrap}>{row.message}</td>
                                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" style={{ textAlign: "center" }}>
                                                        <span className="sql-table-actions" onClick={(event) => { toggle(row); resetOpen(row) }} style={{ cursor: "pointer", fontSize: "50px" }}>
                                                            ...
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr className="sql-table-details">
                                                    <td colSpan={6}>
                                                        <div style={initClosedC} id={`details_${row.id}`}>
                                                            <div className="mt-3">
                                                                <h3>Details</h3>
                                                                <div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">ID</label><span className="ml-3 col-md-8">{row.id}</span>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">Date</label><span className="ml-3 col-md-8">{row.dateStr}</span>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">Level</label><span className="ml-3 col-md-8">{row.level}</span>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">Logger</label><span className="ml-3 col-md-8" style={wordWrap}>{row.logger}</span>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">Method</label><span className="ml-3 col-md-8">{row.method}</span>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">Message</label><div className="ml-3 col-md-8" style={wordWrap}>{row.message}</div>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <label className="col-md-2">Exception</label><div className="ml-3 col-md-8" style={wordWrap}>{row.exception}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="MuiTableCell-root MuiTablePagination-root" colSpan="6">
                                                <PaginationBar page={props.state.page} rowsPerPage={props.state.rowsPerPage} rows={sortedRows}
                                                    handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default {
    getDbLogs: getDbLogs
}