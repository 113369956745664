import React, { useEffect, Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Joi from 'joi-browser';
import Form from "./common/form";
import authService from "../services/authServices";
import userService from "../services/userServices";
import ToggleButton from './common/toggleButton';
import { Redirect, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DateTimeService from "../services/dateTimeService";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 300,
        fontSize: '14px',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    arrow: {
        color: '#000',
    }
}))(Tooltip);

export class AccountCreate extends Form {
    static displayName = AccountCreate.name;

    constructor(props) {
        super(props);
        // var reCAPTCHASite = process.env.REACT_APP_RECAPTCHASITE;
        this.state = {
            data: {
                accountId: props.location.state?.userProfileId == null ? 0 : props.location.state?.userProfileId,
                email: props.location.state?.email == null ? "" : props.location.state?.email,
                accountFirstName: "",
                accountMiddleName: "",
                accountLastName: "",
                companyName: "",
                companyStreet: "",
                companyCity: "",
                companyState: "",
                companyZip: "",
                companyCountry: "",
                accountPhone: "",
                accountTextSms: 0,
                userDataAvailable: false
            },
            country: 'US',
            currentPhoneNumber: '',
            isPhoneVerified: false,
            showPhoneWarning: false,
            errors: {},
            pageEditMode: true,
            accountTextSmsSaved: "",
            //_reCAPTCHA_site: reCAPTCHASite,
            //_reCAPTCHA_site_key: "", 
            //_reCAPTCHA_site_action: "AccountCreate",
            //_reCAPTCHA_token: "",
            returnUrl: "",
            countryList: [],
            clientId: this.props.location.state?.clientId,
            clientName: this.props.location.state?.clientName,
            isNewUser: this.props.location.state?.isNewUser,
            isAdmin: this.props.location.state?.isAdmin === null ? false : this.props.location.state?.isAdmin,
            isNameRequired: this.props.location.state?.isNameRequired === null ? false : this.props.location.state?.isNameRequired,
        }

        this.schema = {
            accountFirstName: Joi.string().trim().max(100).required().error(() => {
                return { message: "First Name required with maximum 100 characters." };
            }),
            accountMiddleName: Joi.string().trim().allow(null, '').max(50).error(() => {
                return { message: "Middle Name has maximum 50 characters." };
            }),
            accountLastName: Joi.string().trim().max(100).required().error(() => {
                return { message: "Last Name required with maximum 100 characters." };
            }),
            companyName: Joi.string().trim().max(100).required().error(() => { return { message: "Company Name is required with maximum 100 characters." } }),
            companyStreet: Joi.string().trim().max(100).required().error(() => {
                return { message: "Street Address is required with maximum 100 characters." };
            }),
            companyCity: Joi.string().trim().max(100).required().error(() => { return { message: "City is required with maximum 100 characters." } }),
            companyState: Joi.string().trim().max(50).required().error(() => { return { message: "State is required with maximum 50 characters." } }),
            companyZip: Joi.string().trim().max(50).required().error(() => { return { message: "Zip is required with maximum 50 characters." } }),
            companyCountry: Joi.string().trim().required().error(() => { return { message: "Country is required." } }),
            accountPhone: Joi.string().when('accountTextSms', {
                is: 1,
                then: Joi.string().min(5).required().error(() => { return { message: "Phone Number is required" } }),
                otherwise: Joi.allow('',null).optional(),
            }),
            accountTextSms: Joi.number(),
            accountId: Joi.number().optional(),
            email: Joi.string().optional(),
            userDataAvailable: Joi.boolean().optional(),
            showPhoneWarning: Joi.boolean().optional()
        };

        this.handleToggleButtonChange = this.handleToggleButtonChange.bind(this);
        //this.handleLoaded = this.handleLoaded.bind(this);
        this.setPhone = this.setPhone.bind(this);
    }

    handleToggleButtonChange(name, value) {
        const data = Object.assign({}, this.state.data);
        data[name] = value === "Yes" ? 1 : 0;
        if (value !== "Yes") {
            this.state.errors["accountPhone"] = "";
            this.setState({
                isPhoneVerified: false
            });
        }
        else {
            this.setState({
                isPhoneVerified: true
            });
        }
        this.setState({ data });
    }
    getCountryList() {
        var data = authService.getCountryList().then((response) => {
            var countryList = response.data.map(name => ({ id: name, name }));
            this.setState({ countryList: countryList });
            this.setState({ countryList: ["", ...countryList] });
            //this.setState({ companyCountry: this.state.countryList[0]});
        });
    }
    async doSubmit() {
        //event.preventDefault();
        //alert('submit');
        let phoneNumber = this.state.data.accountPhone;

        if (phoneNumber &&!phoneNumber.startsWith("+"))
            phoneNumber = `+${phoneNumber}`;

        var request = {
            userProfileId: this.state.data.accountId,
            email: this.state.data.email,
            firstName: this.state.data.accountFirstName,
            middleName: this.state.data.accountMiddleName,
            lastName: this.state.data.accountLastName,
            phone: phoneNumber,
            company: this.state.data.companyName,
            city: this.state.data.companyCity,
            state: this.state.data.companyState,
            zip: this.state.data.companyZip,
            streetAddress: this.state.data.companyStreet,
            country: this.state.data.companyCountry,
            clientId: this.state.clientId,
            isPhoneVerified: this.state.isPhoneVerified,
        }
        //console.log(this.state.errors);
        //Send Otp if the user selected sms option

        const isPhoneUpdated = this.state.data.accountPhone !== this.state.currentPhoneNumber;
        const isSendSmsForNewUser = this.state.isNewUser && this.state.data.accountTextSms;
        const isSendSmsForExistingUserUser = !this.state.isNewUser && this.state.data.accountTextSms && isPhoneUpdated;

        const isSendSms = isSendSmsForNewUser || isSendSmsForExistingUserUser;

        const isNewUserOrNameRequired = this.state.isNewUser || this.state.isNameRequired;

        //console.log("update user data", request);
        //return;
        var data = await authService.updateUser(request, this.state.data.email, isNewUserOrNameRequired).then((response) => {
            //console.log(response);
            if (!response.data.isSuccess) {
                toast.error("Error : Updating user.");
                return false;
            }

            const user = Object.assign({}, response.data.data);


            //console.log("isSendSMS", isSendSms);
            //return;

            if (isSendSms) {
                this.props.history.push({
                    pathname: "/Verify",
                    search: `?ReturnUrl=${this.state.returnUrl}`,
                    state: {
                        email: this.state.data.email,
                        userProfileId: request.userProfileId,
                        isSendSms: isSendSms,
                        phoneNumber: request.phone,
                        clientId: this.state.clientId,
                        clientName: this.state.clientName
                    }
                });

            } else {
                this.props.history.push({
                    pathname: "/Success",
                    search: `?ReturnUrl=${this.state.returnUrl}`,
                    state: {
                        email: this.state.data.email,
                        userProfileId: this.state.data.accountId,
                        clientId: this.state.clientId,
                        clientName: this.state.clientName,
                        showAddPhone: !this.state.isPhoneVerified,
                        showPhoneWarning: false,
                        sucessMessage: "Your account has been updated",
                        phoneNumber: request.phone,
                        isAdmin: this.state.isAdmin
                    }
                });
            }

            //toast.success("User successfully saved.");
        }).catch((error) => {
            //console.log(error);
        });
    }
    CancelSubmit = (e) => {
        e.preventDefault();
        if (this.state.isNewUser) {
            this.props.history.push({
                pathname: "/login",
                search: `?ReturnUrl=${this.state.returnUrl}`,
            });
        }
        else {

            if (!this.state.data.userDataAvailable) {
                this.props.history.push({
                    pathname: "/login",
                    search: `?ReturnUrl=${this.state.returnUrl}`,
                });
            } else {

                this.props.history.push({
                    pathname: "/Success",
                    search: `?ReturnUrl=${this.state.returnUrl}`,
                    state: {
                        email: this.state.data.email,
                        userProfileId: this.state.data.accountId,
                        clientId: this.state.clientId,
                        clientName: this.state.clientName,
                        showAddPhone: !this.state.isPhoneVerified,
                        sucessMessage: "",
                        phoneNumber: this.state.data.accountPhone,
                        isAdmin: this.state.isAdmin
                    }
                });
            }
        }
    }

    //handleLoaded () {
    //     window.grecaptcha.ready(_ => {
    //         window.grecaptcha
    //             .execute(this.state._reCAPTCHA_site_key, { action: this.state._reCAPTCHA_site_action })
    //             .then(token => {
    //                 this.setState({ _reCAPTCHA_token: token });
    //             });
    //     });
    //}

    selectCountry(val) {
        //this.setState({ data.companyCountry: val });
        const data = Object.assign({}, this.state.data);
        if (val !== "") {
            this.state.errors["companyCountry"] = "";
        }
        //console.log(val);
        data['companyCountry'] = val;
        this.setState({ data });
    }
    setPhone(val) {
        const data = Object.assign({}, this.state.data);
        data['accountPhone'] = val;
        this.setState({ data });
        //console.log(val);
    }
    async componentDidMount() {
        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }

        //var captchaKey = await authService.getCaptchaKey();
        //console.log("component did mount");
        //console.log("captchaKey", captchaKey.data);
        //this.setState({ _reCAPTCHA_site_key: captchaKey.data });
        const script = document.createElement("script");
        //script.src = `${this.state._reCAPTCHA_site}=${this.state._reCAPTCHA_site_key}`;
        script.addEventListener("load", this.handleLoaded);
        document.body.appendChild(script);

        const search = this.props.location.search;
        const returnUrl = encodeURIComponent(new URLSearchParams(search).get('ReturnUrl'));
        this.setState({ returnUrl: returnUrl });

        const data = Object.assign({}, this.state.data);
        data['companyCountry'] = "United States";

        //console.log("valdiation");
        //console.log(this.state.isNewUser);
        //console.log(this.state.isNameRequired);
        //console.log(this.props.history);
        if (!this.state.isNewUser && !this.state.isNameRequired) {
            //console.log("Returning User", this.state.data.email);

            try {
                var userResponse = await userService.getUserByEmail(this.state.data.email);

                var userData = userResponse.data;
                //console.log("User Data", userData);

                //console.log("isPhoneVerified: ", userData.isPhoneVerified);


                if (userData.isPhoneVerified)
                    this.setState({
                        currentPhoneNumber: userData.phone,
                        isPhoneVerified: userData.isPhoneVerified
                    });

                data.accountFirstName = userData.firstName;
                data.accountLastName = userData.lastName;
                data.accountMiddleName = userData.middleName;
                data.accountPhone = userData.phone? userData.phone : "+1";
                data.companyCity = userData.city;
                data.companyCountry = userData.country && (userData.country.includes("America") || userData.country.includes("United States")) ? "United States" : userData.country;
                data.companyName = userData.company;
                data.companyState = userData.state;
                data.companyStreet = userData.streetAddress;
                data.companyZip = userData.zip;
                data.accountTextSms = userData.isPhoneVerified ? 1 : 0;
                data.userDataAvailable = (userData.firstName && userData.firstName != "") ? true : false;

                //console.log("showPhoneWarning: ", userData.showPhoneWarning);
                var modifiedDate = new Date(userData.modifiedDate);
                //console.log("modifiedDate: ", DateTimeService.toDateTimeStr(modifiedDate));
                var edgeDate = new Date("2022-05-15T09:20:55.147");
                //console.log("edgeDate: ", DateTimeService.toDateTimeStr(edgeDate));
                if (userData.isPhoneVerified == true || userData.isPhoneVerified == false) {
                    data.showPhoneWarning = false;
                    //console.log("modifiedDate: set 1 ");
                }
                else if (modifiedDate > edgeDate) {
                    data.showPhoneWarning = false;
                    //console.log("modifiedDate: set 2 ");
                }
                else {
                    data.showPhoneWarning = true;
                    //console.log("modifiedDate: set 3 ");
                }
                //console.log("showPhoneWarning: ", data.showPhoneWarning);

            } catch (e) {
                toast.error("Error : Fetching user data.");
            }
        }
        this.setState({ data });
        //this.getCountryList();
    }


    render() {
        return (
            <Fragment>
                <div>
                    <div className="content">
                        <div className="main-content centered-content static-page">
                            <div className="container-md account-container">
                                <div className="content-container">
                                    <div className="container-header">
                                        <h1>{this.state.isNewUser ? "Create" : "Edit"} Account</h1>
                                        <span className="header-subheader">{this.state.data.email}</span>
                                    </div>

                                    <form>
                                        <div className="container-content">
                                            <div className="userinfo-container">
                                                <h2>User Information</h2>
                                                <div className="info-wrapper">
                                                    <div className="form-row">
                                                        {this.renderInput("accountFirstName", "First Name", "info col-lg-4", "text")}
                                                        {this.renderInput("accountMiddleName", "Middle Name", "info col-lg-4", "text", false)}
                                                        {this.renderInput("accountLastName", "Last Name", "info col-lg-4", "text")}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="companyinfo-container">
                                                <h2>Company Information</h2>
                                                <div className="info-wrapper">
                                                    <div className="form-row">
                                                        {this.renderInput("companyName", "Company Name", "info col-lg-4", "text", true, "If no company name applicable, enter 'N/A'")}
                                                        {this.renderInput("companyStreet", "Street Address", "info col-lg-4", "text")}
                                                        {this.renderInput("companyCity", "City", "info col-lg-4", "text")}
                                                    </div>
                                                    <div className="form-row">
                                                        {this.renderInput("companyState", "State/Province", "info col-lg-4", "text")}
                                                        {this.renderInput("companyZip", "Zip", "info col-lg-4", "text")}
                                                        {/*{this.renderInput("companyCountry", "Country", "info col-lg-4", "text")}*/}
                                                        <div className="info col-lg-4">
                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                <label htmlFor="companyCountry">Country </label>
                                                                <CountryDropdown
                                                                    value={this.state.data.companyCountry}
                                                                    valueType={"full"}
                                                                    onChange={(val) => this.selectCountry(val)}
                                                                    id="companyCountry"
                                                                    name="companyCountry"
                                                                    priorityOptions={["US", "CA", "GB"]}
                                                                    defaultOptionLabel=""
                                                                    className={`${this.state.errors["companyCountry"] ? "error-border" : ""}`}
                                                                />
                                                                <small className="error-text">{this.state.errors["companyCountry"]}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="phoneinfo-container">
                                                <h2>Phone Information</h2>
                                                <div className="info-wrapper">
                                                    <div className="form-row">
                                                        {/*{this.renderInput("accountPhone", "Phone", "info col-lg-4", "text", false)}*/}
                                                        <div className="info col-lg-4" >
                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                <label htmlFor="accountPhone">Phone {this.state.data.accountTextSms === 0 && <small>Optional</small>}</label>
                                                                <PhoneInput
                                                                    inputProps={{
                                                                        name:"accountPhone",
																		id:"accountPhone"
                                                                    }}
                                                                    country={"us"}
                                                                    value={this.state.data.accountPhone}
                                                                    inputClass="MuiInputBase-input MuiInput-input"
                                                                    onChange={(val) => this.setPhone(val)}
                                                                    className={`${this.state.errors["accountPhone"] ? "error-border" : ""}`}
                                                                    placeholder=""
                                                                />
                                                                {this.state.data.showPhoneWarning == true && <small className="highlight">Please make sure phone number is in the correct format</small>}
                                                                <small className="error-text">{this.state.errors["accountPhone"]}</small>
                                                            </div>
                                                        </div>
                                                        <div className="info col-lg-4" id="info-textsms">
                                                            <ToggleButton
                                                                name="accountTextSms"
                                                                label="Use Phone for Text (SMS) Verification?"
                                                                options={['Yes', 'No']}
                                                                disabled={false}
                                                                pageEditMode={this.state.pageEditMode}
                                                                value={this.state.data.accountTextSms === 1 ? "Yes" : "No"}
                                                                handleChange={this.handleToggleButtonChange}
                                                                widthClass="toggle-small"
                                                            />
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        Add mobile phone number to receive secure login code via Text(SMS) message
                                                                    </React.Fragment>
                                                                }
                                                                placement="right"
                                                                arrow
                                                            >
                                                                <div className="tooltip-trigger">?</div>
                                                            </HtmlTooltip>
                                                            <small>Note: Data rates may apply.</small>
                                                        </div>
                                                        <div className="info col-lg-4" >
                                                            {this.state.data.accountTextSms === 1 &&
                                                                <div className="text-confirmation">You will be prompted to verify your phone number after saving</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container-buttons" style={{ justifyContent: 'space-between' }}>
                                                <button className="btn cancel" onClick={this.CancelSubmit}>Cancel</button>
                                                <button className="btn primary action" onClick={this.handleSubmit}>Save</button>
                                                {/*<Link to='/success'>*/}
                                                {/*    <button className="btn primary action">Save</button>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}