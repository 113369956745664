import React, { Component } from "react";

export class Header extends Component {

     constructor(props) {
          super(props);
          this.state = {};
     }

     render() {
         return (

             <div className="top-header">
                    <div className="nih-details">
                         <a href="/#" className="logo-nih">
                              <img src="images/logo-nih-mark.svg" className="logo-nih-mark" alt="National Institutes of Health (NIH) Logo" />
                              <img src="images/logo-nih-niaid-keyline-white.svg" className="logo-nih-niaid-text" alt="National Institute of Allergy and Infectious Diseases" />
                         </a>
                         <div className="application-details"><h1>One Time Pin Authentication</h1></div>
                    </div>
               </div>
          );
     }
}