import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

export class toggleButton extends Component {
    constructor (props) {
        super(props);
         this.state = {
            value: props.value
        }; 

        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        widthClass: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value
        }, () => {
            this.props.handleChange(this.props.name, this.state.value);
        });
    }

    render() {
        const ToggleClass = `toggle-switch ${this.props.widthClass}`;
        const pageEditMode = this.props.pageEditMode;

        return (
            <FormControl component="fieldset" className={ToggleClass}>
                <FormLabel component="legend">{this.props.label}</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={this.props.value} onChange={this.handleChange} className={`active-index-${this.props.options.findIndex((f) => f === this.props.value)}`}>
                    { this.props.options.map((option, index) => (
                        <FormControlLabel
                            key={this.props.name + index}
                            value={option}
                            readOnly={!pageEditMode}
                            disabled={this.props.disabled}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        )
    }
}

export default toggleButton;
