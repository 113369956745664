import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';


//Pagination functions
function TablePaginationActions(props) {
    //const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    let isFirstPage = page === 0;
    let isLastPage = page === lastPage;
    const backwardClass = `MuiButtonBase-root MuiIconButton-root ${isFirstPage ? "Mui-disabled" : ""}`;
    const forwardClass = `MuiButtonBase-root MuiIconButton-root ${isLastPage ? "Mui-disabled" : ""}`;

    return (
        <div className="root">
            <button className={backwardClass} tabIndex="-1" type="button" disabled="" aria-label="first page"
                onClick={handleFirstPageButtonClick}>
                <span className="MuiIconButton-label">
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    </svg>
                </span>
            </button>
            <button className={backwardClass} tabIndex="-1" type="button" disabled="" aria-label="previous page"
                onClick={handleBackButtonClick} >
                <span className="MuiIconButton-label">
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                    </svg>
                </span>
            </button>
            <button className={forwardClass} tabIndex="-1" type="button" disabled="" aria-label="next page"
                onClick={(e) => handleNextButtonClick(e)}>
                <span className="MuiIconButton-label">
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z">
                        </path>
                    </svg>
                </span>
            </button>
            <button className={forwardClass} tabIndex="-1" type="button" disabled="" aria-label="last page"
                onClick={handleLastPageButtonClick}>
                <span className="MuiIconButton-label">
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z">
                        </path>
                    </svg>
                </span>
            </button>
        </div>
    );
}

export default function PaginationBar(props) {
    const { page, rowsPerPage, rows, handleChangeRowsPerPage, handleChangePage } = props;
    return (
        <div className="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters d-flex justify-content-between align-items-center">
            {/*<div style={{ flex: "1 1 20%" }}></div>*/}
            {/*<div className="d-flex justify-content-end"></div>*/}
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <p className="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit" id="mui-68025">Rows per page:</p>
                <div className="MuiInputBase-root MuiTablePagination-input MuiTablePagination-selectRoot">
                    <select className="MuiSelect-root MuiSelect-select MuiTablePagination-select MuiInputBase-input" aria-label="rows per page" id="mui-8235" defaultValue={rowsPerPage} onChange={handleChangeRowsPerPage}>
                        <option className="MuiTablePagination-menuItem" value="10">10</option>
                        <option className="MuiTablePagination-menuItem" selected="selected" value="25">25</option>
                        <option className="MuiTablePagination-menuItem" value="50">50</option>
                        <option className="MuiTablePagination-menuItem" value="100">100</option>
                        <option className="MuiTablePagination-menuItem" value={rows.length}>All</option>
                    </select>
                    <svg className="MuiSvgIcon-root MuiSelect-icon MuiTablePagination-selectIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M7 10l5 5 5-5z">
                        </path>
                    </svg>
                </div>
                <p className="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit">
                    {rows.length > 0 ? page * rowsPerPage + 1 : 0}-{rows.length > 0 ? ((page + 1) * rowsPerPage < rows.length ? (page + 1) * rowsPerPage : rows.length) : 0} of {rows.length}
                </p>
            </div>
            <div>
                <TablePaginationActions onChangePage={handleChangePage} page={page} count={rows.length} rowsPerPage={rowsPerPage} />
            </div>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};