
function toDateTimeStr(dtStr) {
    const dateTime = new Date(dtStr); 
    const yr = dateTime.getFullYear();
    const mon = dateTime.getMonth() + 1;
    const dy = dateTime.getDate();
    const hr = dateTime.getHours();
    let min = (dateTime.getMinutes() < 10 ? '0' : '') + dateTime.getMinutes();
    let sec = (dateTime.getSeconds() < 10 ? '0' : '') + dateTime.getSeconds();

    let newHr = 0;
    let pa = "AM";
    if (hr === 0) {
        newHr = 12;
        pa = "AM";
    }
    else if (hr === 12) {
        newHr = 12;
        pa = "PM";
    }
    else if (hr < 12) {
        newHr = hr;
        pa = "AM";
    }
    else {
        newHr = hr - 12;
        pa = "PM";
    }

    let str = mon + "/" + dy + "/" + yr + " " + newHr + ":" + min + ":" + sec + " " + pa;

    return str;
}

export default {
    toDateTimeStr: toDateTimeStr
}

