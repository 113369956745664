import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";

//todo: We are not using this file as TokenExpired file is being used, move the codes to TokenExpired and delete this?
export class Timeout extends Component {
    static displayName = Timeout.name;

    constructor(props) {
        super(props);
        this.state = {
            showBackdrop: true
        }
    }

    render() {
        return (
            <div>
                {this.state.showBackdrop && <div className="backdrop"></div>}
                <div className="content" style={{ zIndex: "1050", }}>
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <h1>Session Has Expired</h1>
                                </div>
                                <form>
                                    <div className="container-content">
                                        <div className="timeout-container">
                                            <span>Because you have been inactive, your session has expired.</span>
                                        </div>
                                        <div className="container-buttons" >
                                            <Link to='/home'>
                                                <button className="btn primary action">Login Again</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="container-sm disclaimer-container">
                            <span>
                                You are accessing a U.S. Government information system which includes this computer, network, and all attached devices. This system is for Government-authorized use only. Unauthorized use of this system may result in disciplinary action and civil and criminal penalties. System users have no expectation of privacy regarding any communications or data processed by this system. Personally identifiable information (PII) entered into the OTP is used for NIH business processes. PII is information that is personal in nature that may be used to identify an individual. When a user account is established, PII is requested about users in the roles of applicants, awardees or the institutional organization staff/key personnel. For more information about NIH privacy notice please visit: <a className="disclaimer-link" href="/#">NIH Web Policies and Notices</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
