import http from "./httpService";



export async function getUserToVerifyByEmail(email, notify = true) {
    email = Buffer.from(email).toString('base64');
    return http.get(`user/getUserToVerifyByEmail/${email}/${notify}`);
}
export async function getUserByEmail(email) {
    email = Buffer.from(email).toString('base64');
    return http.get(`user/getuserByEmail/${email}`);
}

export async function getUserById(profileId) {
    return http.get(`user/getUserById/${profileId}`);
}

export async function searchUsers(searchText) {
    return http.get(`user/searchUsers/${searchText}`);
}

export async function updateProfile(updateRequest) {
    //console.log("Update User method call");
    return http.post("user/updateProfile", updateRequest)
        .catch(err => {
            return err;
        });
}

export async function updateUserAccess(request) {
    //console.log("Update User method call");
    return http.post("user/updateUserAccess", request)
        .catch(err => {
            return err;
        });
}

export async function getUserAccess(profileId) {
    return http.get(`user/getUserAccess/${profileId}`);
}

export async function removeUserAccess(request) {
    //console.log("Update User method call");
    return http.post("user/removeUserAccess", request)
        .catch(err => {
            return err;
        });
}

export function haveIdSrvSession(input) { return /^(.*;)?\s*idsrv.session\s*=/.test(input); }

export default {
    getUserToVerifyByEmail: getUserToVerifyByEmail,
    getUserByEmail: getUserByEmail,
    searchUsers: searchUsers,
    getUserById: getUserById,
    updateProfile: updateProfile,
    getUserAccess: getUserAccess,
    updateUserAccess: updateUserAccess,
    removeUserAccess: removeUserAccess,
    haveIdSrvSession: haveIdSrvSession
  };