import React, { useState, useEffect, Fragment } from 'react';
import { LinkMenu } from './LinkMenu';
import { Checkbox } from '@material-ui/core';
import EnhancedTableHead from "../common/enhancedTableHead";
import HTTPStatusCodes from '../../enums/HTTPStatusCodes';
import PaginationBar from "../common/pagination";
import userServices from '../../services/userServices';
import sortingServices from '../../services/sortingServices';

import { Redirect, Link } from "react-router-dom";
import { toast } from "react-toastify";
import SettingsService from '../../services/settingsService';
import StringService from '../../services/stringService';


//Header
const headCells = [
    { id: 'name', align: 'left', label: 'Name', sort: true, width: '22%' },
    { id: 'value', align: 'left', label: 'Value', sort: true, width: '22%' },
    { id: 'isActive', align: 'left', label: 'IsActive', sort: true, width: '8%' },
    { id: 'note', align: 'center', label: 'Note', sort: true, width: '32%' },
    { id: 'action', align: 'center', label: 'Action', sort: false, width: '10%' }
];


export function Settings(props) {
    const [searchText, setSearchText] = useState([]);

    const [loading, setLoading] = useState(false);

    const [find, setFind] = useState('');
    const [rowOpened, setRowOpened] = useState({ row: null, detailId: null });
    const [uiRows, setUiRows] = useState([]);
    const [rows, setRows] = useState([]);

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [reRender, setReRender] = useState(false);

    //let emptyRows = rowsPerPage - Math.min(rowsPerPage, uiRows.length - page * rowsPerPage);
    let sortedRows = [];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!props.history.location.key) {
            //console.log("REDIRECT TO ERROR PAGE");
            props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
        }

        else {
            let mounted = true;
            (async () => {
                try {
                    let res = await SettingsService.getSettings();
                    let rowsLength = res.data.length;
                    //emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsLength - page * rowsPerPage);
                    setPage(0);
                    let data = res.data;

                    for (let d of data) {
                        //d.createdDateStr = dateTimeService.toDateTimeStr(d.createdDate);
                        //d.modifiedDateStr = dateTimeService.toDateTimeStr(d.modifiedDate);
                        const boolStr = d.isActive ? "Yes" : "No";
                        d.allStr = boolStr + " " + d.name + " " + d.value + " "
                            + d.createdDateStr + " " + d.modifiedDateStr + " " + d.note;
                        d.allStr = d.allStr.toLowerCase();
                    }

                    if (mounted) {
                        setFind("");
                        setRows(data);
                        setUiRows(data);
                    }
                }
                catch (ex) {
                    toast.error("Fetch Settings failed");
                }
            })();

            return () => {
                mounted = false;
            };
        }       
    }, []);

    //const handleEditProfile = (event, row) => {
    //    console.log("handle edit profile:", row);
    //};
    const nameChangeH = (event) => {
        let v = event.target.value;
        if (typeof v !== "undefined") {
            if (!rowOpened.row) return;
            rowOpened.row.newName = v;
            setReRender(!reRender);
        }
    }

    const valueChangeH = (event) => {
        let v = event.target.value;
        if (typeof v !== "undefined") {
            if (!rowOpened.row) return;
            rowOpened.row.newValue = v;
            setReRender(!reRender);
        }
    }

    const activeChangeH = (event) => {
        let v = event.target.checked;
        if (typeof v !== "undefined") {
            if (!rowOpened.row) return;
            rowOpened.row.newIsActive = v;
            setReRender(!reRender);
        }
    }

    const noteChangeH = (event) => {
        let v = event.target.value;
        if (typeof v !== "undefined") {
            if (!rowOpened.row) return;
            rowOpened.row.newNote = v;
            setReRender(!reRender);
        }
    }

    const editH = () => {
        if (!rowOpened.row) return;
        rowOpened.row.mode = "Edit";
        setReRender(!reRender);
    }

    const cancelH = () => {
        if (!rowOpened.row) return;
        rowOpened.row.newName = rowOpened.row.name;
        rowOpened.row.newValue = rowOpened.row.value;
        rowOpened.row.newIsActive = rowOpened.row.isActive;
        rowOpened.row.newNote = rowOpened.row.note;
        rowOpened.row.mode = "View";
        setReRender(!reRender);
    }

    const sanitize = (row) => {
        if (!row) return row;
        row.newName = StringService.trimEx(row.newName);
        row.newValue = StringService.trimEx(row.newValue); 
        row.newNote = StringService.trimEx(row.newNote); 
        return row;
    }

    const disolveNewValues = (row) => {
        if (!row) return row;
        row.name = row.newName;
        row.value = row.newValue;
        row.isActive = row.newIsActive;
        row.note = row.newNote;
        return row;
    }

    const saveH = () => {
        if (!rowOpened.row) return;
        setLoading(true);
        var body = disolveNewValues(sanitize(rowOpened.row));
        SettingsService.updateSetting(body)
            .then((res) => {
                if (res.status === HTTPStatusCodes.SUCCESS) {
                    rowOpened.row.mode = "View";
                    rowOpened.row.name = res.data.name;
                    rowOpened.row.newName = res.data.newName;
                    rowOpened.row.value = res.data.value;
                    rowOpened.row.newValue = res.data.newValue;
                    rowOpened.row.isActive = res.data.isActive;
                    rowOpened.row.newIsActive = res.data.newIsActive;
                    rowOpened.row.note = res.data.note;
                    rowOpened.row.newNote = res.data.newNote;
                    rowOpened.row.modifiedDate = res.data.modifiedDate;
                    rowOpened.row.modifiedDateStr = res.data.modifiedDateStr; //dateTimeService.toDateTimeStr(res.data.modifiedDate);

                    setReRender(!reRender);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Update fail");
                }
            })
            .catch(err => {
                setLoading(false);
                toast.error("Internal server error");
            });
    }

    const resetOpen = (row) => {
        if (rowOpened && rowOpened.row && rowOpened.row.id !== row.id) {
            rowOpened.row.isOpen = false;
            cancelH();
            var obj = document.getElementById('details_' + rowOpened.row.id);
            if (obj) {
                obj.style.opacity = "0";
                obj.style.height = '0';
            }
        }
        setRowOpened({ row: row, detailId: "deatails_" + row.id });
        setReRender(!reRender);
    }

    const toggle = (r) => {
        var obj = document.getElementById('details_' + r.id);

        if (!r.isOpen) {
            r.isOpen = true;
            obj.style.opacity = '1';
            //obj.style.height = obj.scrollHeight + 'px';
            obj.style.height = 'auto';
        }
        else {
            r.isOpen = false;
            obj.style.opacity = '0';
            obj.style.height = '0';
        }
        setReRender(!reRender);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInput = e => {
        setSearchText(e.target.value);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        //console.log("user clicked search - todo fetch user data from backend");
        setLoading(true);
        userServices.searchUsers(searchText).then((response) => {
            //console.log(response);

            setPage(0);
            let data = response.data;
            setUiRows(data);
            setLoading(false);

        }).catch((error) => {

            toast.error("Error in userSearch");
            //console.log(error);
        });

    }


    //Generic table stuff
    const loader = (<div className="loader" style={{ position: "fixed", top: "30%", left: "45%" }}>Loading...</div>);
    sortedRows = sortingServices.stableSort(uiRows, sortingServices.getComparator(order, orderBy));
    const trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
    const wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }

    const initClosedC = { transition: 'all 0.3s ease 0.3s', display: "block", height: "0", opacity: "0", overflow: 'hidden' };

    return (
        <div>
            <div className="content">
                <div className="main-content centered-content static-page">
                    <div className="container-md userprofile-container">
                        <div className="content-container">
                            <div className="container-header">
                                <h1>Settings</h1>

                                <LinkMenu {...props.location.state} />
                            </div>

                            <form>
                                <div className="container-content sql-search-container">
                                    {/*<div className="search-container">*/}
                                    {/*    <div className="info-wrapper">*/}
                                    {/*        <div className="form-row">*/}
                                    {/*            <div className="info col-lg-8">*/}
                                    {/*                <div className="MuiFormControl-root MuiTextField-root">*/}
                                    {/*                    */}{/* {this.renderInput("searchText", "Search User", "info col-lg-8", "text", true)}*/}
                                    {/*                    <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="clientName" id="clientName-label">User Name</label>*/}
                                    {/*                    <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">*/}
                                    {/*                        <input aria-invalid="false" id="searchText" name="searchText" required="" value={searchText} onChange={handleSearchInput} type="text" className="MuiInputBase-input MuiInput-input" />*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <button className="btn btn-primary search" onClick={handleSearch}>Search</button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="results-container sql-table-container">
                                        <div className="info-wrapper">
                                            {loading && loader}
                                            <table className="MuiTable-root" style={{ width: "100%" }}>
                                                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={uiRows.length} />
                                                <tbody className="MuiTableBody-root">
                                                    {(rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows).map((row, index) => (
                                                        <React.Fragment key={row.id}>
                                                            <tr className="MuiTableRow-root MuiTableRow-hover" style={trMain(index)}>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={wordWrap} data-label='Name'><div>{row.name}</div></td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Value'><div>{row.value}</div></td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='IsActive'><div>{row.isActive ? "Yes" : "No"}</div></td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" data-label='Note'><div>{row.note}</div></td>
                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter" data-label='Action'>
                                                                    <span className="sql-table-actions" >
                                                                        <span className="action-icon" style={{ backgroundColor: "green", color: "white" }}
                                                                            onClick={(event) => { toggle(row); resetOpen(row) }}>
                                                                            
                                                                            {!row.isOpen && `Edit`}
                                                                            {row.isOpen && `Close`}
                                   
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr className="sql-table-details">
                                                                <td colSpan={5}>
                                                                    <div style={initClosedC} id={`details_${row.id}`}>
                                                                        <div className="mt-3">
                                                                            <h3>Edit</h3>
                                                                            <div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2" htmlFor="rowId">ID</label><span className="col-md-8">{row.id}</span>
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2">Name</label><span className="col-md-4">{row.name}</span>
                                                                                    {row.mode == "Edit" && <input id="rowId" className="col-md-5 MuiInputBase-input MuiInput-input" value={row.newName} onChange={nameChangeH} />}
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2" htmlFor="value">Value</label><span className="col-md-4">{row.value}</span>
                                                                                    {row.mode == "Edit" && <textarea id="value" className="col-md-5 MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline" value={row.newValue} onChange={valueChangeH} />}
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2" htmlFor="isActive">Is Active</label><span className="col-md-4">{row.isActive ? "Yes" : "No"}</span>
                                                                                    {row.mode == "Edit" && <Checkbox className="col-md-1" id="isActive" checked={row.newIsActive} onChange={activeChangeH} />}
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2">Created Date</label><span className="col-md-8">{row.createdDateStr}</span>
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2">Modified Date</label><span className="col-md-8">{row.modifiedDateStr}</span>
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <label className="col-md-2">Note</label><span className="col-md-4" style={wordWrap}>{row.note}</span>
                                                                                    {row.mode == "Edit" && <textarea className="col-md-5 MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline" value={row.newNote} onChange={noteChangeH} />}
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <div className="col-md-10"></div>
                                                                                    <div className="col-md-2 row mb-3">
                                                                                        <span className="sql-table-actions" >
                                                                                            {row.mode === "View" && <span className="action-icon" style={{ backgroundColor: "green", color: "white", marginRight: "3px" }} onClick={editH}>
                                                                                                Edit
                                                                                            </span>}
                                                                                            {row.mode === "Edit" && <span className="action-icon" style={{ backgroundColor: "grey", color: "white", marginRight: "3px" }} onClick={cancelH}>
                                                                                                Cancel
                                                                                            </span>}
                                                                                            {row.mode === "Edit" && <span className="action-icon" style={{ backgroundColor: "green", color: "white" }} onClick={saveH}>
                                                                                                Save
                                                                                            </span>}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td className="MuiTableCell-root MuiTablePagination-root" colSpan="8">
                                                            <PaginationBar page={page} rowsPerPage={rowsPerPage} rows={sortedRows}
                                                                handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} />
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    );

}
