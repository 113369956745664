import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Redirect, Link } from "react-router-dom";

import AuthService from '../../services/authServices';

export class LinkMenu extends Component {
    static displayName = LinkMenu.name;

    constructor(props) {
        super(props);
        this.state = {
        }



        this.handleApplicationRedirect = this.handleApplicationRedirect.bind(this);
    }

    handleApplicationRedirect(event) {
        //console.log("TODO Redirecting to Application");
        event.preventDefault();
        var request = {
            ReturnUrl: this.props.returnUrl,
            ClientId: this.props.clientId,
            LoginEmail: this.props.email
        };
        var data = AuthService.redirectToApplication(request).then((response) => {
           
            if (response.data === 'ERR') {
                this.props.history.push({
                    pathname: "/Error",
                    state: { errorMessage: response.message }
                });
            }
            var authorizeEndPoint = response.data;
            
            const redirectUrl = authorizeEndPoint.redirectUrl;
            window.location.replace(redirectUrl);
        });
    }

    render() {
        return (
            <div className="link-menu header-select">
                <div className="MuiFormControl-root MuiTextField-root">
                    <Dropdown>
                        <label id="linkmenu-label" htmlFor="dropdown-basic">GO TO</label>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            OTP Admin
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                            align="end">
                            <Dropdown.Item > <Link to={{
                                pathname: `/UserSearch`,
                                state: {
                                    clientId: this.props.clientId,
                                    email: this.props.email,
                                    returnUrl: this.props.returnUrl
                                }
                            }} >Manage User Profile</Link></Dropdown.Item>
                            <Dropdown.Item ><Link to={{
                                pathname: `/ClientSearch`,
                                state: {
                                    clientId: this.props.clientId,
                                    email: this.props.email,
                                    returnUrl: this.props.returnUrl
                                }
                            }} >Manage Client Profile</Link></Dropdown.Item>
                            <Dropdown.Item ><Link to={{
                                pathname: `/ViewLogs`,
                                state: {
                                    clientId: this.props.clientId,
                                    email: this.props.email,
                                    returnUrl: this.props.returnUrl
                                }
                            }} >View Logs</Link></Dropdown.Item>
                            <Dropdown.Item ><Link to={{
                                pathname: `/Settings`,
                                state: {
                                    clientId: this.props.clientId,
                                    email: this.props.email,
                                    returnUrl: this.props.returnUrl
                                }
                            }} >Settings</Link></Dropdown.Item>
                            <Dropdown.Item onClick={this.handleApplicationRedirect}>Continue to {this.props.clientId}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    }
}