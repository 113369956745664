import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import AuthService from '../services/authServices';
import { datadogRum } from '@datadog/browser-rum';
import UserServices from '../services/userServices';
import HTTPStatusCodes from "../enums/HTTPStatusCodes";
import OtpType from "../enums/OtpType";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import userService from '../services/userServices';

import { toast } from "react-toastify";
export class Verify extends Component {
    static displayName = Verify.name;

    constructor(props) {
        super(props);
        //console.log("verify component", this.props);
        this.state = {
            returnUrl: "",
            userProfileId: this.props.location.state?.userProfileId !== undefined ? props.location.state?.userProfileId : 0,
            email: this.props.location.state?.email !== undefined ? this.props.location.state?.email : "",
            phoneNumber: "",
            userEnteredPhoneNumber: "",
            otpCode: "",
            resentMessage: "",
            errors: {
                Email: ""
            },
            isAdmin: this.props.location.state?.isAdmin == null ? false : this.props.location.state?.isAdmin,
            isSendSms: false,
            clientId: this.props.location.state?.clientId,
            clientName: this.props.location.state?.clientName,
            isVerificationForAccountUpdate: this.props.location.state?.isVerificationForAccountUpdate,
            previousScreen: this.props.location.state?.previousScreen,
            invalidCount: 0
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resendOTPCode = this.resendOTPCode.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    async componentDidMount() {

        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }
        var isvaliduser = true;

        if (this.state.email && this.state.userProfileId != 0) {
            datadogRum.setGlobalContextProperty("user", this.state.email);
            await userService.getUserToVerifyByEmail(this.state.email, false).then(response => {
                //console.log(response);
                if (response.status === 204) {
                    //console.log("User not exist");
                    isvaliduser = false;
                    this.props.history.push({
                        pathname: "/Error",
                        state: { errorMessage: 'Invalid User Details' }
                    });
                    return;
                }
                else {
                    if (response.data.isAccountLocked) {
                        isvaliduser = false;
                        this.props.history.push({
                            pathname: "/Error",
                            state: { errorMessage: 'Your account has been locked. Please contact ' }
                        });
                        return;
                    }
                }

            })
                .catch(error => {
                    //console.log(error);
                    return;
                });
        }
        if (isvaliduser) {
            try {
                const search = this.props.location.search;
                const returnUrl = encodeURIComponent(new URLSearchParams(search).get('ReturnUrl'));
                //const returnUrl = this.props.location.state.returnUrl;
                this.setState({ returnUrl: returnUrl });

                let isSendSms = this.props.location.state.isSendSms !== undefined ? this.props.location.state.isSendSms : false;
                if (this.state.isVerificationForAccountUpdate) isSendSms = false;
                await this.setState({ isSendSms: isSendSms });
                const phoneNumber = this.props.location.state.phoneNumber !== undefined ? this.props.location.state.phoneNumber : "";
                const userEnteredPhoneNumber = phoneNumber;
                let maskedPhoneNumber = "";
                if (phoneNumber) {
                    let startIdx = phoneNumber.length - 4;
                    startIdx = startIdx > 0 ? startIdx : 0;
                    maskedPhoneNumber = phoneNumber.substring(0, 2) + "*".repeat(6) + phoneNumber.substring(startIdx);
                }

                await this.setState({ phoneNumber: maskedPhoneNumber, userEnteredPhoneNumber });

                //console.log("isSendSMS:" + isSendSms);
                //console.log("phoneNumber:" + phoneNumber);
                //console.log("profileId:" + this.state.userProfileId);
                //console.log(this.props.location.state.email);

                //todo: send otp if existing User
                if (this.state.userProfileId) {
                    //send Otp code comes here
                    let otpDestination = this.state.isSendSms ? this.state.userEnteredPhoneNumber : this.state.email;
                    var request = {
                        destination: otpDestination,
                        userProfileId: this.state.userProfileId,
                        typeId: this.state.isSendSms ? OtpType.Phone : OtpType.Email
                    }

                    var data = AuthService.sendOtpCodeForUser(request).then((response) => {
                        //console.log(response);
                         if (!response.data.isSuccess || response.data.data === "0") {
                            //console.log("Error- send otp code for user ", response.data);
                            this.setState(prevState => ({
                                errors: {
                                    ...prevState,
                                    Email: response.data.message
                                }
                            }));

                             if (response.data.indexOf("Your support ID") >= 0) {
                                toast.error(`The requested URL was rejected. Please try again later.`);
                             }

                             if (response.data.data === "User is locked") {
                                this.props.history.push({
                                    pathname: "/Error",
                                    state: { errorMessage: 'Your account has been locked. Please contact ' }
                                });
                            }

                            return false;
                        }
                        otpDestination = this.state.isSendSms ? this.state.phoneNumber : otpDestination;
                        toast.success(`OTP successfully sent to ${otpDestination}`);
                        AuthService.getDeliveryStatus();
                        //console.log(`OTP successfully sent to ${otpDestination}`);
                    }).catch((error) => {
                        //console.log(error);
                    });
                }



                let menu = document.getElementById('accountPhone');
                if (menu) {
                    let children = menu.children[0].children;
                    let item = children[1];
                    let phoneFormatted = item['value'];
                    this.setState({ phoneFormatted });
                }


                //console.log('children = ', children);
                //console.log('item = ', item);
                //console.log('phoneFormatted = ', phoneFormatted);

            } catch (ex) {
                //console.log("Error happened in Verify");
            }
        }
    }

    resendOTPCodeToNewUser() {
        var request = {
            email: this.state.email
        }
        var data = AuthService.resendOtpCodeForNewUser(request).then((response) => {
            if (!response.data.isSuccess) {
                this.setState(prevState => (
                    {
                        errors: {
                            ...prevState.errors,
                            OTPCode: response.data.message
                        }
                    }));

                if (response.data.indexOf("Your support ID") >= 0) {
                    toast.error(`The requested URL was rejected. Please try again later.`);
                }

                if (response.data.data === "User is locked") {
                    this.props.history.push({
                        pathname: "/Error",
                        state: { errorMessage: 'Your account has been locked. Please contact ' }
                    });
                }

                return false;
            }
            toast.success("New OTP Code has been sent.");
        }).catch((error) => {
            //console.log(error);
        });
    }

    resendOTPCode(event) {
        event.preventDefault();
        this.setState({ resentMessage: "" });
        if (this.state.userProfileId === 0) {
            var request = {
                email: this.state.email
            }
            var data = AuthService.resendOtpCodeForNewUser(request).then((response) => {
                //console.log(response);
                if (!response.data.isSuccess) {
                    this.setState(prevState => (
                        {
                            errors: {
                                ...prevState.errors,
                                OTPCode: response.data.message
                            }
                        }));

                    if (response.data.indexOf("Your support ID") >= 0) {
                        toast.error(`The requested URL was rejected. Please try again later.`);
                    }

                    if (response.data.data === "User is locked") {
                        this.props.history.push({
                            pathname: "/Error",
                            state: { errorMessage: 'Your account has been locked. Please contact ' }
                        });
                    }

                    return false;
                }
                //this.setState({ resentMessage: "OTP code has been sent to your email." });
                toast.success("OTP Code has been sent");
            }).catch((error) => {
                //console.log(error);
            });
        }
        //todo: consider resend to email as well
        if (this.state.userProfileId !== 0) {

            const otpDestination = this.state.isSendSms ? this.state.userEnteredPhoneNumber : this.state.email;
            var request = {
                destination: otpDestination,
                userProfileId: this.state.userProfileId,
                typeId: this.state.isSendSms ? OtpType.Phone : OtpType.Email
            }

            var data = AuthService.sendOtpCodeForUser(request).then((response) => {
                //console.log(response);
                if (!response.data.isSuccess || response.data.data === "0") {
                    console.log("Error - send otp code for user ", response.data);
                    if (response.data.data === "User is locked") {
                        this.props.history.push({
                            pathname: "/Error",
                            state: { errorMessage: 'Your account has been locked. Please contact ' }
                        });
                        //toast.error(response.data.message);
                    }
                    
                    return false;
                }
                //console.log(`New OTP successfully sent to ${otpDestination}`);
                toast.success(`New OTP successfully sent to ${otpDestination}`);
            }).catch((error) => {
                //console.log(error);
            });

        }
    }
    handleSubmit(event) {
        event.preventDefault();
        if (!this.Validate()) {
            return false;
        }
        //console.log(this.state.isSendSms);
        if (this.state.userProfileId === 0) {
            var request = {
                destination: this.state.email,
                otpCode: this.state.otpCode,
                TypeId: OtpType.Email,
                clientId: this.state.clientId
            }
            var data = AuthService.validateOtpCodeForNewUser(request).then((response) => {
                //console.log(response);
                if (!response.data.isSuccess) {
                    var count = this.state.invalidCount;
                    this.setState({ invalidCount: (count + 1) });

                    if (response.data.data === 'AccountLocked') {
                        //console.log("Account Locked");
                        this.props.history.push({
                            pathname: "/Error",
                            state: { errorMessage: response.message }
                        });
                    }
                    this.setState(prevState => (
                        {
                            errors: {
                                ...prevState.errors,
                                OTPCode: response.data.message
                            }
                        }));

                    if (response.data.indexOf("Your support ID") >= 0) {
                        toast.error(`The requested URL was rejected. Please try again later.`);
                    }
                    if (response.data.data === "User is locked") {
                        this.props.history.push({
                            pathname: "/Error",
                            state: { errorMessage: 'Your account has been locked. Please contact ' }
                        });
                    } else {
                        toast.error(response.data.message);
                        if (this.state.invalidCount == 3) {
                            this.resendOTPCodeToNewUser();
                            this.setState({ invalidCount: 0 });
                        }
                    }
                    return false;
                }

                const user = Object.assign({}, response.data.data);
                this.props.history.push({
                    pathname: "/AccountCreate",
                    search: `?ReturnUrl=${this.state.returnUrl}`,
                    state: {
                        email: this.state.email,
                        userProfileId: user.userProfileId,
                        clientId: this.state.clientId,
                        clientName: this.state.clientName,
                        isNewUser: true
                    }
                });
            }).catch((error) => {
                //console.log(error);
            });
        }
        if (this.state.userProfileId !== 0) {
            const otpDestination = this.state.isSendSms ? this.state.userEnteredPhoneNumber : this.state.email;
            var request = {
                destination: otpDestination,
                otpCode: this.state.otpCode,
                userProfile: this.state.userProfileId,
                TypeId: this.state.isSendSms ? OtpType.Phone : OtpType.Email,
                clientId: this.state.clientId
            }
            //console.log(request);
            var data = AuthService.verifyOtp(request).then((response) => {
                //console.log("OTP Verify Response");
                //console.log(response);
                if (!response.data.isSuccess) {
                    if (response.data.data === 'AccountLocked') {
                        //console.log("Account Locked");
                        this.props.history.push({
                            pathname: "/Error",
                            state: { errorMessage: response.data.message }
                        });
                    }

                    this.setState(prevState => (
                        {
                            errors: {
                                ...prevState.errors,
                                OTPCode: response.data.message
                            }
                        }));
                    toast.error(response.data.message);
                    return false;
                }

                const user = Object.assign({}, response.data.data);
                let phone = this.state.phoneNumber;
                if (!this.state.isSendSms) {

                    if (this.state.isVerificationForAccountUpdate) {
                        this.props.history.push({
                            pathname: `/AccountCreate`,
                            search: `?ReturnUrl=${this.state.returnUrl}`,
                            state: {
                                email: this.state.email,
                                userProfileId: this.state.userProfileId,
                                clientId: this.state.clientId,
                                clientName: this.state.clientName,
                                isNewUser: false
                            }
                        });

                    } else {
                        var userResponse = UserServices.getUserToVerifyByEmail(this.state.email)
                            .then((user) => {
                                //console.log("user", user);
                                phone = user.data.phone;
                                this.props.history.push({
                                    pathname: "/Success",
                                    search: `?ReturnUrl=${this.state.returnUrl}`,
                                    state: {
                                        email: this.state.email,
                                        userProfileId: this.state.userProfileId,
                                        clientId: this.state.clientId,
                                        clientName: this.state.clientName,
                                        isAdmin: response.data.isAdmin,
                                        isNameRequired: response.data.isNameRequired,
                                        showAddPhone: !response.data.isPhoneVerified,
                                        phoneNumber: phone,
                                        isVerifiedThroughPhone: this.state.isSendSms
                                    }
                                });
                            });
                        //console.log("userResponse", userResponse);
                    }


                }
                else {
                    //console.log("userRefinelaphonesponse", phone);
                    this.props.history.push({
                        pathname: "/Success",
                        search: `?ReturnUrl=${this.state.returnUrl}`,
                        state: {
                            email: this.state.email,
                            userProfileId: this.state.userProfileId,
                            clientId: this.state.clientId,
                            clientName: this.state.clientName,
                            isAdmin: response.data.isAdmin,
                            isNameRequired: response.data.isNameRequired,
                            showAddPhone: !response.data.isPhoneVerified,
                            phoneNumber: phone,
                            isVerifiedThroughPhone: this.state.isSendSms
                        }
                    });
                }
            }).catch((error) => {
                //console.log(error);
            });
        }
    }

    handleChange(event) {
        var otpCode = event.target.value.replace(/[^0-9]/g, "");
        this.setState({ otpCode: otpCode, errors: {} });
    }

    Validate() {
        var errors = {};
        var iserror = false;
        if (this.state.email.trim() === "") {
            errors.Email = "Email is required.";
            iserror = true;
        }
        if (this.state.otpCode.trim() === "") {
            errors.OTPCode = "OTP Code is required.";
            iserror = true;
        }
        this.setState({ errors: errors || {} });
        if (iserror) return false;
        return true;
    }

    goBack() {
        this.props.history.goBack();
    }


    handleBackClick(event) {
        event.preventDefault();

        if (this.state.previousScreen === 'Success') {
            this.props.history.push({
                pathname: "/Success",
                search: `?ReturnUrl=${this.state.returnUrl}`,
                state: {
                    email: this.state.email,
                    userProfileId: this.state.userProfileId,
                    clientId: this.state.clientId,
                    clientName: this.state.clientName,
                    isAdmin: this.state.isAdmin,
                    isNameRequired:false,
                    showAddPhone: false,
                    phoneNumber: this.state.userEnteredPhoneNumber,
                    isVerifiedThroughPhone: true
                }
            });

        }
        else if (this.state.previousScreen == 'VerifyPhone') {
            this.props.history.push({
                pathname: "/VerifyPhone",
                search: `?ReturnUrl=${this.state.returnUrl}`,
                state: {
                    email: this.state.email,
                    userProfileId: this.state.userProfileId,
                    clientId: this.state.clientId,
                    clientName: this.state.clientName,
                    isNewUser: false,
                    phoneNumber: this.state.userEnteredPhoneNumber,
                    returnUrl: this.state.returnUrl
                }
            });
        }
        else if (this.state.previousScreen == 'Authenticate') {
            this.props.history.push({
                pathname: 'authenticate/' + this.state.email,
                search: ``,
                state: {
                    email: this.state.email,
                    userProfileId: this.state.userProfileId,
                    clientId: this.state.clientId,
                    clientName: this.state.clientName,
                    isNewUser: false,
                    phoneNumber: this.state.userEnteredPhoneNumber,
                    returnUrl: this.state.returnUrl
                }
            });
        }
        else {
            this.props.history.push({
                pathname: '/AccountCreate',
                search: `?ReturnUrl=${this.state.returnUrl}`,
                state: {
                    email: this.state.email,
                    userProfileId: this.state.userProfileId,
                    clientId: this.state.clientId,
                    clientName: this.state.clientName,
                    isNewUser: false,
                    phoneNumber: this.state.userEnteredPhoneNumber,
                    returnUrl: this.state.returnUrl
                }
            });

        }
    }

    render() {
        const pageStyle = {
            /*paddingLeft: "10px",*/
            color: "red"
        }
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content static-page">
                        <div className="container-sm login-container">
                            <div className="content-container">
                                <div className="container-header">
                                    <span className="header-subheader">Sign in</span>
                                    <h1>Enter your security code</h1>
                                    <span className="email">{this.state.email}</span>
                                </div>
                                {/**/}
                                <div className="container-content">
                                    {!this.state.isSendSms &&
                                        <div className="text">
                                            <span>A one-time verification code has been sent to the above email.  The code will be active for 8 hours.</span>
                                        </div>}
                                    {this.state.isSendSms &&
                                        <div className="text">
                                            <span>A one-time verification code has been sent to the phone {this.state.phoneNumber}. The code will be active for 20 minutes.
                                                <span id="accountPhone">
                                                    <PhoneInput
                                                        inputProps={{
                                                            disabled: true,
                                                            disabledDropdown: true,
                                                            disabledCountryCode: true
                                                        }}
                                                        name="accountPhone"
                                                        value={this.state.phoneNumber}
                                                        containerClass="verifyNumber"
                                                    />
                                                </span>
                                            </span>
                                        </div>}
                                    <form>
                                        <div className="verify-container">
                                            <div className="form-row" id="verify-code">
                                                <div className="info col-lg-12">
                                                    <label htmlFor="security-code">One-time Security Code </label>
                                                    {/* Not use number type because it will allow e, + and -  */}
                                                    <input type="text" id="security-code" onChange={this.handleChange} value={this.state.otpCode} />
                                                    <small className="text-danger" style={{ display: "block" }}>{this.state.errors.Email}</small>
                                                    <small className="text-danger" style={{ display: "block" }}>{this.state.errors.OTPCode}</small>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-lg-12">
                                                    Didn't receive the code?
                                                    <ul>
                                                        <li>Double check your {this.state.isSendSms ? 'phone number' : 'email address'}</li>
                                                        <li>Check your {this.state.isSendSms ? 'phone ' : ''}spam folder{this.state.isSendSms ? ' if applicable' : ''}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <button type="button" className="btn secondary new-code" onClick={this.resendOTPCode}>Send New Code</button>
                                                <span>{this.state.resentMessage}</span>
                                            </div>
                                        </div>
                                        <div className="container-buttons" style={{ justifyContent: 'space-between' }}>

                                            {this.state.userProfileId === 0 &&
                                                <Link to={`/login?ReturnUrl=${this.state.returnUrl}`} >
                                                    <button type="button" className="btn cancel">Cancel</button>
                                                </Link>
                                            }

                                            {this.state.userProfileId !== 0 &&

                                                <button type="button" className="btn cancel" onClick={this.handleBackClick}>Back</button>

                                            }


                                            <button type="submit" className="btn primary action" onClick={this.handleSubmit}>Next</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}