import http from "./httpService";

export async function searchClients(searchText) {
    return http.get(`client/searchClients/${searchText}`);
}

export async function getClient(id) {
    return http.get(`client/getClient/${id}`);
}

export async function toggleClientActivation(id) {
    return http.post(`client/toggleClientActivation/${id}`);
}

export async function createOrUpdateClient(request) {
    return http.post("client/createOrUpdateClient", request)
        .catch(err => {
            return err;
        });
}

export async function getClientScope(id) {
    return http.get(`client/getClientScope/${id}`);
}

export async function removeClientScope(request) {
    return http.post("client/removeClientScope", request)
        .catch(err => {
            return err;
        });
}

export async function addClientScope(request) {
    return http.post("client/addClientScope", request)
        .catch(err => {
            return err;
        });
}

export async function getClientGrant(clientSequenceId) {
    return http.get(`client/getGrantType/${clientSequenceId}`);
}

export async function removeGrantType(request) {
    return http.post("client/removeGrantType", request)
        .catch(err => {
            return err;
        });
}

export async function addGrantType(request) {
    return http.post("client/addGrantType", request)
        .catch(err => {
            return err;
        });
}


export async function getClientSecret(clientSequenceId) {
    return http.get(`client/getClientSecret/${clientSequenceId}`);
}

export async function removeClientSecret(request) {
    return http.post("client/removeClientSecret", request)
        .catch(err => {
            return err;
        });
}

export async function addClientSecret(request) {
    return http.post("client/addClientSecret", request)
        .catch(err => {
            return err;
        });
}

export async function getClientPostLogoutRedirectUri(clientSequenceId) {
    return http.get(`client/getClientPostLogoutRedirectUri/${clientSequenceId}`);
}

export async function removeClientPostLogoutRedirectUri(request) {
    return http.post("client/removeClientPostLogoutRedirectUri", request)
        .catch(err => {
            return err;
        });
}

export async function addClientPostLogoutRedirectUri(request) {
    return http.post("client/addClientPostLogoutRedirectUri", request)
        .catch(err => {
            return err;
        });
}

export async function getClientRedirectUri(clientSequenceId) {
    return http.get(`client/getClientRedirectUri/${clientSequenceId}`);
}

export async function removeClientRedirectUri(request) {
    return http.post("client/removeClientRedirectUri", request)
        .catch(err => {
            return err;
        });
}

export async function addClientRedirectUri(request) {
    return http.post("client/addClientRedirectUri", request)
        .catch(err => {
            return err;
        });
}

export default {
    searchClients: searchClients,
    getClient: getClient,
    toggleClientActivation: toggleClientActivation,
    createOrUpdateClient: createOrUpdateClient,
    getClientScope: getClientScope,
    removeClientScope: removeClientScope,
    addClientScope: addClientScope,
    getClientGrant: getClientGrant,
    addGrantType: addGrantType,
    removeGrantType: removeGrantType,
    getClientSecret: getClientSecret,
    removeClientSecret: removeClientSecret,
    addClientSecret: addClientSecret,
    getClientPostLogoutRedirectUri: getClientPostLogoutRedirectUri,
    removeClientPostLogoutRedirectUri: removeClientPostLogoutRedirectUri,
    addClientPostLogoutRedirectUri: addClientPostLogoutRedirectUri,
    getClientRedirectUri: getClientRedirectUri,
    removeClientRedirectUri: removeClientRedirectUri,
    addClientRedirectUri: addClientRedirectUri
};
