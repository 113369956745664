import React from "react";

const Input = ({ name, label, error, type, required, placeholder, readOnly, disabled, value, inputSize, onChange }) => {

  return (
    <div className={inputSize}>
        <div className="MuiFormControl-root MuiTextField-root">
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor={name} id={`${name}-label`}>
              {label}
              {!required && <small>Optional</small>}
            </label>
            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                  <input id={name} type={type} name={name} readOnly={readOnly} disabled={disabled} className={`MuiInputBase-input MuiInput-input ${error ? "error-border" : ""}`} defaultValue={value} onInput={onChange} placeholder={placeholder} />
              </div>
            {error && <small className="error-text">{error}</small>}
        </div>
    </div>
  );
};

export default Input;
