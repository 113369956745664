import React, { Component } from 'react';

export class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="footer-logos">
                                <a href="https://www.hhs.gov/" alt="www.hhs.gov" aria-label="visit HHS.gov" target="_blank" rel="noopener noreferrer">
                                    <img src="images/logo-hhs-white.svg" className="logo-footer-hhs" alt="Health and Human Services (HHS) Logo" />
                                </a>
                                <a href="https://www.nih.gov/" alt="www.nih.gov" aria-label="visit NIH.gov" target="_blank" rel="noopener noreferrer">
                                    <img src="images/logo-nih-mark-white.svg" alt="National Institute of Health (NIH) Logo" className="logo-footer-nih" />
                                </a>
                                <a href="https://www.usa.gov/" alt="www.usa.gov" aria-label="visit USA.gov" target="_blank" rel="noopener noreferrer">
                                    <img src="images/logo-usagov-white.svg" alt="USA.gov Logo" className="logo-footer-usagov" />
                                </a>
                                <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" alt="www.hhs.gov/vulnerability-disclosure-policy/" aria-label="visit HHS vulnerability disclosure policy" target="_blank" rel="noopener noreferrer" className="vulnerability-link">
                                    HHS Vulnerability Disclosure Policy
                                </a>
                            </div>
                            {/*<div className="footer-text">
                                <div className="application-details">
                                    <div className="footer-title">OTP</div>
                                    <div className="footer-version">2021.3.0</div>
                                    <div className="footer-version" hidden="">Build Date: 10/14/2021, 6:16:42 PM</div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div></div>
        );
    }
}
