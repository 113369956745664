import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Joi from 'joi-browser';
import Form from "../common/form";
import clientServices from '../../services/clientServices';

import ToggleButton from '../common/toggleButton';
import { Redirect, Link } from "react-router-dom";
import { Tabs, Tab, Badge } from 'react-bootstrap';
import { LinkMenu } from './LinkMenu';
import { toast } from "react-toastify";

export class ClientProfile extends Form {
    static displayName = ClientProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            data: this.getInitialStateData(),
            errors: {},
            pageEditMode: true,
            selectedTab: 'clientProfile',
            scope: [{ id: "openid", name: "openid" }, { id: "otp.api.user.profile", name: "otp.api.user.profile" }, { id: "otp.user.profile", name: "otp.user.profile" }],
            customScopeRequiredMessage: "",
            customScope: "",
            grant: [{ id: "implicit", name: "implicit" }, { id: "client_credentials", name: "client_credentials" }],
            secret: "",
            redirect: "",
            clientSequenceId: this.props.location.state !== undefined ? this.props.location.state.clientSequenceId : -1,
            isNewClient: false,
            clientScopeMessage: "",
            clientScopeData: [],
            clientGrantMessage: "",
            clientGrantData: [],
            clientSecretMessage: "",
            clientSecretData: [],
            clientSecretRequiredMessage: "",

            
            clientPostLogoutRedirectUri: "",
            clientPostLogoutRedirectUriMessage: "",
            clientPostLogoutRedirectUriData: [],
            clientPostLogoutRedirectUriRequiredMessage: "",

            clientRedirectUri: "",
            clientRedirectUriMessage: "",
            clientRedirectUriData: [],
            clientRedirectUriRequiredMessage: "",
        }

        this.schema = {
            allowAccessTokensViaBrowser: Joi.boolean(),
            alwaysIncludeUserClaimsInIdToken: Joi.boolean(),
            clientId: Joi.string().trim().max(200).required().error(() => {
                return { message: "Client Id required with maximum 200 characters." };
            }),
            clientName: Joi.string().trim().max(200).required().error(() => {
                return { message: "Client Name required with maximum 200 characters." };
            }),
            clientSequenceId: Joi.number(),
            applicationGroupId: Joi.number(),
            clientUri: Joi.string().trim().required().error(() => {
                return { message: "Client Uri required" };
            }),
            displayName: Joi.string().trim().max(500).required().error(() => {
                return { message: "Display Name required with maximum 500 characters." };
            }),
            enabled: Joi.boolean(),
            logoutUri: Joi.string().trim().required().error(() => {
                return { message: "Client Uri required" };
            }),
            requireConsent: Joi.boolean(),
            scope: Joi.allow('').optional(),
            customScope: Joi.allow('').optional(),
            grant: Joi.allow('').optional(),

        };

        this.handleToggleButtonChange = this.handleToggleButtonChange.bind(this);
        this.handleAddClient = this.handleAddClient.bind(this);
        this.getInitialStateData = this.getInitialStateData.bind(this);
        this.handleTabSelection = this.handleTabSelection.bind(this);
        this.getClientScopeData = this.getClientScopeData.bind(this);
        this.handleDeleteScope = this.handleDeleteScope.bind(this);
        this.handleAddPreDefinedScope = this.handleAddPreDefinedScope.bind(this);
        this.handleAddCustomScope = this.handleAddCustomScope.bind(this);
        this.onCustomScopeChange = this.onCustomScopeChange.bind(this);
        this.saveClientScope = this.saveClientScope.bind(this);

        this.getClientGrantData = this.getClientGrantData.bind(this);
        this.handleDeleteGrantType = this.handleDeleteGrantType.bind(this);
        this.handleAddGrantType = this.handleAddGrantType.bind(this);

        this.getClientSecretData = this.getClientSecretData.bind(this);
        this.handleDeleteClientSecret = this.handleDeleteClientSecret.bind(this);
        this.handleAddClientSecret = this.handleAddClientSecret.bind(this);
        this.onClientSecretChange = this.onClientSecretChange.bind(this);

        this.getClientPostLogoutRedirectUriData = this.getClientPostLogoutRedirectUriData.bind(this);
        this.handleDeleteClientPostLogoutRedirectUri = this.handleDeleteClientPostLogoutRedirectUri.bind(this);
        this.handleAddClientPostLogoutRedirectUri = this.handleAddClientPostLogoutRedirectUri.bind(this);
        this.onClientPostLogoutRedirectUriChange = this.onClientPostLogoutRedirectUriChange.bind(this);

        this.getClientRedirectUriData = this.getClientRedirectUriData.bind(this);
        this.handleDeleteClientRedirectUri = this.handleDeleteClientRedirectUri.bind(this);
        this.handleAddClientRedirectUri = this.handleAddClientRedirectUri.bind(this);
        this.onClientRedirectUriChange = this.onClientRedirectUriChange.bind(this);


    }

    getInitialStateData() {
        return {
            allowAccessTokensViaBrowser: false,
            alwaysIncludeUserClaimsInIdToken: false,
            clientId: "",
            clientName: "",
            clientSequenceId: -1,
            applicationGroupId: -1,
            clientUri: "",
            displayName: "",
            enabled: false,
            logoutUri: "",
            requireConsent: false,
            scope: "",
            grant: "",
            
        }
    }

    handleAddClient(e) {
        e.preventDefault();

        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        const data = Object.assign({}, this.getInitialStateData());
        
        this.setState({ data, isNewClient: true, clientSequenceId: -1 });
        
    }

    handleToggleButtonChange(name, value) {
        const data = Object.assign({}, this.state.data);
        data[name] = value === "Yes" ? true : false;
        this.setState({ data });
    }

    async componentDidMount() {
        if (!this.props.history.location.key) {
            this.props.history.push({
                pathname: "/Error",
                state: { errorMessage: 'Invalid URL Access' }
            });
            return;
        }

        if (this.state.clientSequenceId < 0) { 
            this.setState({ isNewClient: true });
            return;
        }


        clientServices.getClient(this.state.clientSequenceId).then((response) => {
            
            const clientData = response.data.data;
            const data = Object.assign(this.state.data, clientData);

            this.setState({ data, isNewClient: false });

        }).catch((error) => {

            toast.error("Error in Client data Retrieval");
            
        });
    }


    handleAddPreDefinedScope(e) {
        e.preventDefault();

        
        const scopeName = this.state.data.scope;
        const existingScope = this.state.clientScopeData.find(obj => obj.scopeName === scopeName);
        if (!scopeName || existingScope) {
            const errors = {};
            errors['scope'] = existingScope ? "Duplicate Scope Not Allowed" : "Select scope before proceeding";
            this.setState({ errors });
            return;
        }
        
        this.saveClientScope(scopeName);

    }

    handleAddGrantType(e) {
        e.preventDefault();

        
        const grantTypeName = this.state.data.grant;
        const existingGrant = this.state.clientGrantData.find(obj => obj.grantTypeName === grantTypeName);
        if (!grantTypeName || existingGrant) {
            const errors = {};
            errors['grant'] = existingGrant ? "Duplicate Grant Not Allowed" : "Select grant before proceeding";
            this.setState({ errors });
            return;
        }
        
        //console.log("Save grant in back end");
        const request = { clientSequenceId: this.state.clientSequenceId, grantTypeName }
        
        clientServices.addGrantType(request).then((response) => {
            //console.log(response);
            const clientGrantData = response.data.data;

            const clientGrantMessage = response.data.data ? "" : this.state.clientGrantMessage;

            this.setState({ clientGrantData, errors: {}, clientGrantMessage});
            toast.success("Grant Type Added");

        }).catch((error) => {

            toast.error("Error in Client Grant Type Addition");
            
        });

    }

    onCustomScopeChange({ currentTarget: input }) {
        
        const customScope = input.value.trim();
        
        if (customScope) {
            this.setState({ customScope, customScopeRequiredMessage: "" });
        }

    }

    onClientSecretChange({ currentTarget: input }) {
        const secret = input.value.trim();
        
        if (secret) {
            this.setState({ secret, clientSecretRequiredMessage: "" });
        }

    }

    onClientPostLogoutRedirectUriChange({ currentTarget: input }) {
        const clientPostLogoutRedirectUri = input.value.trim();
        if (clientPostLogoutRedirectUri) {
            this.setState({ clientPostLogoutRedirectUri, clientPostLogoutRedirectUriRequiredMessage: "" });
        }

    }

    onClientRedirectUriChange({ currentTarget: input }) {
        const clientRedirectUri = input.value.trim();
        if (clientRedirectUri) {
            this.setState({ clientRedirectUri, clientRedirectUriRequiredMessage: "" });
        }

    }

    handleAddClientRedirectUri(e) {
        e.preventDefault();
        const clientRedirectUri = this.state.clientRedirectUri.trim();

        

        const existingRedirectUri = this.state.clientRedirectUriData.find(obj => obj.redirectUri === clientRedirectUri);
        if (!clientRedirectUri || existingRedirectUri) {
            this.setState({ clientRedirectUriRequiredMessage: existingRedirectUri ? "Duplicate RedirectUri Not Allowed" : "RedirectUri Value Required" });
            return;
        }

        var validUri = /^(Http|Https|http|https):\/\/[^ "]+$/.test(clientRedirectUri);

        if (!validUri) {
            this.setState({ clientRedirectUriRequiredMessage: "Invalid RedirectUri" });
            return;
        }

        const request = { clientSequenceId: this.state.clientSequenceId, redirectUri: clientRedirectUri }

       
        clientServices.addClientRedirectUri(request).then((response) => {
            //console.log(response);
            const clientRedirectUriData = response.data.data;
           
            this.setState({ clientRedirectUriData, clientRedirectUriMessage: "", clientRedirectUri: "" });
            document.getElementById("clientRedirectUri").value = "";
            toast.success("Client RedirectUri Added");

        }).catch((error) => {

            toast.error("Error in Client RedirectUri Addition");
           
        });

    }

    handleAddClientPostLogoutRedirectUri(e) {
        e.preventDefault();
        const clientPostLogoutRedirectUri = this.state.clientPostLogoutRedirectUri.trim();

        var validUri = /^(Http|Https|http|https):\/\/[^ "]+$/.test(clientPostLogoutRedirectUri);

        if (!validUri) {
            this.setState({ clientPostLogoutRedirectUriRequiredMessage: "Invalid PostLogoutRedirectUri" });
            return;
        }

        const existingPostLogoutRedirectUri = this.state.clientPostLogoutRedirectUriData.find(obj => obj.postLogoutRedirectUri === clientPostLogoutRedirectUri);
        if (!clientPostLogoutRedirectUri || existingPostLogoutRedirectUri) {
            this.setState({ clientPostLogoutRedirectUriRequiredMessage: existingPostLogoutRedirectUri ? "Duplicate PostLogoutRedirectUri Not Allowed" : "PostLogoutRedirectUri Value Required" });
            return;
        }

        const request = { clientSequenceId: this.state.clientSequenceId, postLogoutRedirectUri: clientPostLogoutRedirectUri, clientPostLogoutRedirectUri: "" }
        document.getElementById("clientPostLogoutRedirectUri").value = "";

        clientServices.addClientPostLogoutRedirectUri(request).then((response) => {
            //console.log(response);
            const clientPostLogoutRedirectUriData = response.data.data;
            
            this.setState({ clientPostLogoutRedirectUriData, clientPostLogoutRedirectUriMessage: "" });
            toast.success("Client PostLogoutRedirectUri Added");

        }).catch((error) => {

            toast.error("Error in Client PostLogoutRedirectUri Addition");
            //console.log(error);
        });

    }

    handleAddClientSecret(e) {
        e.preventDefault();
        const clientSecretValue = this.state.secret.trim();
        const existingSecret = this.state.clientSecretData.find(obj => obj.clientSecretValue === clientSecretValue);
        if (!clientSecretValue || existingSecret) {
            
            this.setState({ clientSecretRequiredMessage: existingSecret ? "Duplicate Secret Not Allowed" : "Secret Value Required" });
            return;
        }

        const request = { clientSequenceId: this.state.clientSequenceId, clientSecretValue }

        clientServices.addClientSecret(request).then((response) => {
            //console.log(response);
            const clientSecretData = response.data.data;
           
            this.setState({ clientSecretData, clientSecretMessage: "", secret: "" });
            document.getElementById("clientSecret").value = "";
            toast.success("Client Secret Added");

        }).catch((error) => {

            toast.error("Error in Client Secret Addition");
            //console.log(error);
        });

    }

    handleAddCustomScope(e) {
        e.preventDefault();
        const scopeName = this.state.customScope;
        const existingScope = this.state.clientScopeData.find(obj => obj.scopeName === scopeName);
        if (!scopeName || existingScope) {
            this.setState({ customScopeRequiredMessage: existingScope ? "Duplicate Scope Not Allowed" : "Custom Scope Name Required" });
            return;
        }
        this.saveClientScope(scopeName);
    }

    saveClientScope(scopeName) {


        const request = { clientSequenceId: this.state.clientSequenceId, scopeName }
        clientServices.addClientScope(request).then((response) => {
            //console.log(response);
            const clientScopeData = response.data.data;

            this.setState({ clientScopeData, clientScopeMessage: "", customScope:"", errors:{}});
            document.getElementById("customScope").value = "";
            toast.success("Client Scope Added");

        }).catch((error) => {

            toast.error("Error in Client Scope Addition");
            //console.log(error);
        });

    }
    handleTabSelection(eventKey) {
        
        this.setState({ selectedTab: eventKey });
        switch (eventKey) {
            case 'clientProfile':
                break;
            case 'clientScope':
                this.getClientScopeData();
                break;
            case 'clientGrantType':
                this.getClientGrantData();
                break;
            case 'clientSecret':
                this.getClientSecretData();
                break;
            case 'clientPostLogout':
                this.getClientPostLogoutRedirectUriData();
                break;
            case 'clientRedirect':
                this.getClientRedirectUriData();
                break;
            default:
                //console.log(`Not Implemented event key:  ${eventKey}.`);
        }
    }

    trMain = (i) => {
        if (i % 2 === 0) return { backgroundColor: "#fff" };
        else if ((i + 1) % 2 === 0) return { backgroundColor: "#EEF2f7" };
    }
    wordWrap = {
        overflowWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",
        overflowX: "hidden"
    }

    getClientScopeData() {
        if (this.state.clientSequenceId <= 0) return;
        clientServices.getClientScope(this.state.clientSequenceId).then((response) => {
            //console.log(response);
            const clientScopeData = response.data.data;
            const clientScopeMessage = response.data.message;

            this.setState({ clientScopeData, clientScopeMessage });

        }).catch((error) => {

            toast.error("Error in Client Scope data Retrieval");
            //console.log(error);
        });
    }

    getClientGrantData() {
        if (this.state.clientSequenceId <= 0) return;
        
        clientServices.getClientGrant(this.state.clientSequenceId).then((response) => {
            //console.log(response);
            const clientGrantData = response.data.data;
            const clientGrantMessage = response.data.message;
            
            this.setState({ clientGrantData, clientGrantMessage });

        }).catch((error) => {

            toast.error("Error in Client Grant data Retrieval");
            //console.log(error);
        });
    }

    getClientSecretData() {
        if (this.state.clientSequenceId <= 0) return;
        clientServices.getClientSecret(this.state.clientSequenceId).then((response) => {
            //console.log(response);
            const clientSecretData = response.data.data;
            const clientSecretMessage = response.data.message;
           
            this.setState({ clientSecretData, clientSecretMessage });

        }).catch((error) => {

            toast.error("Error in Client Secret data Retrieval");
            //console.log(error);
        });
    }

    getClientPostLogoutRedirectUriData() {
        if (this.state.clientSequenceId <= 0) return;
        
        clientServices.getClientPostLogoutRedirectUri(this.state.clientSequenceId).then((response) => {
            
            const clientPostLogoutRedirectUriData = response.data.data;
            const clientPostLogoutRedirectUriMessage = response.data.message;

            this.setState({ clientPostLogoutRedirectUriData, clientPostLogoutRedirectUriMessage });

        }).catch((error) => {

            toast.error("Error in Client PostLogoutRedirectUri data Retrieval");
            //console.log(error);
        });
    }

    getClientRedirectUriData() {
        if (this.state.clientSequenceId <= 0) return;
        clientServices.getClientRedirectUri(this.state.clientSequenceId).then((response) => {
           
            const clientRedirectUriData = response.data.data;
            const clientRedirectUriMessage = response.data.message;

            this.setState({ clientRedirectUriData, clientRedirectUriMessage });

        }).catch((error) => {

            toast.error("Error in Client RedirectUri data Retrieval");
            //console.log(error);
        });
    }

    async handleDeleteScope(e, clientScopeId, clientSequenceId) {
        e.preventDefault();
        const request = { clientScopeId, clientSequenceId }

        clientServices.removeClientScope(request).then((response) => {
            const clientScopeData = response.data.data;
            const clientScopeMessage = response.data.message;

            this.setState({ clientScopeData, clientScopeMessage , errors:{}});
            toast.success("Client Scope Removed");

        }).catch((error) => {

            toast.error("Error in Client Scope Removal");
            //console.log(error);
        });


    }

    async handleDeleteGrantType(e, clientGrantTypeId, clientSequenceId) {
        e.preventDefault();
        const request = { clientGrantTypeId, clientSequenceId }


        clientServices.removeGrantType(request).then((response) => {
           
            const clientGrantData = response.data.data;
            const clientGrantMessage = response.data.message;

            this.setState({ clientGrantData, clientGrantMessage, errors: {} });
            toast.success("Grant Type Removed");

        }).catch((error) => {

            toast.error("Error in Grant Type Removal");
            //console.log(error);
        });


    }

    async handleDeleteClientSecret(e, clientSecretId, clientSequenceId) {
        e.preventDefault();
        const request = { clientSecretId, clientSequenceId }
       
        clientServices.removeClientSecret(request).then((response) => {
            //console.log(response);
            const clientSecretData = response.data.data;
            const clientSecretMessage = response.data.message;
            

            this.setState({ clientSecretData, clientSecretMessage });
            toast.success("Client Secret Removed");

        }).catch((error) => {

            toast.error("Error in Client Secret Removal");
            //console.log(error);
        });


    }

    async handleDeleteClientPostLogoutRedirectUri(e, clientPostLogoutRedirectUriId, clientSequenceId) {
        e.preventDefault();
        const request = { clientPostLogoutRedirectUriId, clientSequenceId }

        clientServices.removeClientPostLogoutRedirectUri(request).then((response) => {
            //console.log(response);
            const clientPostLogoutRedirectUriData = response.data.data;
            const clientPostLogoutRedirectUriMessage = response.data.message;


            this.setState({ clientPostLogoutRedirectUriData, clientPostLogoutRedirectUriMessage });
            toast.success("Client PostLogoutRedirectUri Removed");

        }).catch((error) => {

            toast.error("Error in Client PostLogoutRedirectUri Removal");
            //console.log(error);
        });


    }

    async handleDeleteClientRedirectUri(e, clientRedirectUriId, clientSequenceId) {
        e.preventDefault();
        const request = { clientRedirectUriId, clientSequenceId }

        clientServices.removeClientRedirectUri(request).then((response) => {
            //console.log(response);
            const clientRedirectUriData = response.data.data;
            const clientRedirectUriMessage = response.data.message;


            this.setState({ clientRedirectUriData, clientRedirectUriMessage });
            toast.success("Client RedirectUri Removed");

        }).catch((error) => {

            toast.error("Error in Client RedirectUri Removal");
            //console.log(error);
        });


    }

    async doSubmit() {

        clientServices.createOrUpdateClient(this.state.data).then((response) => {
            //console.log(response);
            const clientData = response.data.data;
            const data = Object.assign(this.state.data, clientData);
            toast.success(response.data.message);
            this.setState({ data, isNewClient: false, clientSequenceId: clientData.clientSequenceId });

        }).catch((error) => {

            toast.error(`Error in Client ${this.state.clientSequenceId < 0 ? "Creation" : "Update"}`);
            //console.log(error);
        });
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="content">
                        <div className="main-content centered-content static-page">
                            <div className="container-md userprofile-container">
                                <div className="content-container">
                                    <div className="container-header">
                                        <h1>Manage Client Profile</h1>
                                        <span className="header-subheader">{this.state.data.clientName}</span>
                                        <LinkMenu {...this.props.location.state}/>
                                    </div>

                                    <div className="row">
                                        <div className={`internal-page-tabs tab-content ${this.state.isEdit ? "col-xl-9" : "col-xl-12"}`}>
                                            <Tabs defaultActiveKey="clientProfile" activeKey={this.state.selectedTab} onSelect={(eventKey) => { this.handleTabSelection(eventKey) }} transition={false} id="solTab">
                                                <Tab eventKey="clientProfile" title="Profile">
                                                    <div id="client-profile" role="tabpanel" aria-label="client-profile-tab">
                                                        <div className="tile-section form-section user-profile">

                                                            <form>
                                                                <div className="container-content">
                                                                    <div className="clientinfo-container">
                                                                        <div className="clientinfo-title-button">
                                                                            <h4>Client Information</h4>
                                                                            {!this.state.isEdit &&
                                                                                <button className="btn primary action" onClick={e => this.handleAddClient(e)}>Add</button>}
                                                                        </div>

                                                                        <div className="info-wrapper">
                                                                            <div className="form-row">
                                                                                {this.renderInput("clientName", "Client Name", "info col-lg-6", "text")}
                                                                                {this.renderInput("clientId", "Client Id", "info col-lg-6", "text", true, null, !this.state.isNewClient)}
                                                                            </div>
                                                                            <div className="form-row">
                                                                                <div className="info col-lg-6" id="info-accountLocked">
                                                                                    <ToggleButton
                                                                                        name="enabled"
                                                                                        label="Enabled"
                                                                                        options={['Yes', 'No']}
                                                                                        disabled={false}
                                                                                        pageEditMode={this.state.pageEditMode}
                                                                                        value={this.state.data.enabled ? "Yes" : "No"}
                                                                                        handleChange={this.handleToggleButtonChange}
                                                                                        widthClass="toggle-small"
                                                                                    />
                                                                                </div>

                                                                                {this.renderInput("displayName", "Client Display Name", "info col-lg-6", "text")}
                                                                            </div>
                                                                            <div className="form-row">
                                                                                {this.renderInput("clientUri", "Client URI", "info col-lg-6", "text")}
                                                                                {this.renderInput("logoutUri", "Logout URI", "info col-lg-6", "text")}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="permission-container">
                                                                        <h2>Permission Information</h2>
                                                                        <div className="info-wrapper">
                                                                            <div className="form-row">
                                                                                <div className="info col-lg-4" id="info-allowTokens">
                                                                                    <ToggleButton
                                                                                        name="allowAccessTokensViaBrowser"
                                                                                        label="Allow Access Tokens via Browser"
                                                                                        options={['Yes', 'No']}
                                                                                        disabled={false}
                                                                                        pageEditMode={this.state.pageEditMode}
                                                                                        value={this.state.data.allowAccessTokensViaBrowser ? "Yes" : "No"}
                                                                                        handleChange={this.handleToggleButtonChange}
                                                                                        widthClass="toggle-small"
                                                                                    />
                                                                                </div>
                                                                                <div className="info col-lg-4" id="info-includeUserClaims">
                                                                                    <ToggleButton
                                                                                        name="alwaysIncludeUserClaimsInIdToken"
                                                                                        label="Always Include User Claims in IdToken"
                                                                                        options={['Yes', 'No']}
                                                                                        disabled={false}
                                                                                        pageEditMode={this.state.pageEditMode}
                                                                                        value={this.state.data.alwaysIncludeUserClaimsInIdToken ? "Yes" : "No"}
                                                                                        handleChange={this.handleToggleButtonChange}
                                                                                        widthClass="toggle-small"
                                                                                    />
                                                                                </div>
                                                                                <div className="info col-lg-4" id="info-requireConsent">
                                                                                    <ToggleButton
                                                                                        name="requireConsent"
                                                                                        label="Require Consent"
                                                                                        options={['Yes', 'No']}
                                                                                        disabled={false}
                                                                                        pageEditMode={this.state.pageEditMode}
                                                                                        value={this.state.data.requireConsent ? "Yes" : "No"}
                                                                                        handleChange={this.handleToggleButtonChange}
                                                                                        widthClass="toggle-small"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="container-buttons" style={{ justifyContent: 'space-between' }}>

                                                                        <Link to={{
                                                                            pathname: `/ClientSearch`,
                                                                            state: {
                                                                                clientId: this.props.location.state.clientId,
                                                                                email: this.props.location.state.email,
                                                                                returnUrl: this.props.location.state.returnUrl
                                                                            }
                                                                        }} >
                                                                            <button className="btn cancel">Cancel</button>
                                                                        </Link>

                                                                        <button className="btn primary action" onClick={this.handleSubmit}>Save</button>

                                                                    </div>
                                                                </div>

                                                            </form>

                                                        </div>
                                                    </div>
                                                </Tab>
                                                {this.state.clientSequenceId > 0 && (
                                                    <Tab eventKey="clientScope" title="Scope">
                                                        <div id="client-scope" role="tabpanel" aria-label="client-scope-tab">
                                                            <div className="tile-section form-section">
                                                                <form>
                                                                    <div className="text text-warning"><img src="/images/icon-exclamation-circle-orange.svg" alt="alert icon" /> Note: Scope can have multiple values. You can select either pre-defined scope or enter custom scope.</div>
                                                                    <div className="row params">
                                                                        {this.renderSelect("scope", "Select Pre-defined Scope Name", this.state.scope, "info col-lg-4 col-md-4", true)}
                                                                        <div className="col-lg-2">
                                                                            <button className="btn primary action" onClick={e => this.handleAddPreDefinedScope(e)}>Add</button>
                                                                        </div>
                                                                        <div className="info col-lg-5">
                                                                            <div className="MuiFormControl-root MuiTextField-root">
                                                                                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="customScope" id={`customScope-label`}>
                                                                                    Custom Scope Name
                                                                                </label>
                                                                                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                    <input id="customScope" type="text" name="customScope" maxLength="200" className={`MuiInputBase-input MuiInput-input ${this.state.customScopeRequiredMessage ? "error-border" : ""}`} defaultValue={this.state.customScope} onInput={this.onCustomScopeChange} placeholder="Enter custom scope or select from the dropdown list" />
                                                                                </div>
                                                                                {this.state.customScopeRequiredMessage && <small className="error-text">{this.state.customScopeRequiredMessage}</small>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-1">
                                                                            <button className="btn primary action" onClick={e => this.handleAddCustomScope(e)}>Add</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <div className="info-wrapper results">
                                                                    {this.state.clientScopeMessage && (<div className="col-lg-12">
                                                                        {this.state.clientScopeMessage}
                                                                    </div>)}

                                                                    {!this.state.clientScopeMessage && (<div className="col-lg-12">
                                                                        <table className="MuiTable-root" style={{ width: "100%" }}>
                                                                            <TableHead>
                                                                                <TableRow className="header">
                                                                                    <TableCell key='ScopeName' align='left' width='85%'>Scope Name</TableCell>
                                                                                    <TableCell key='Action' align='right' width='15%'>Action</TableCell>
                                                                                </TableRow>
                                                                            </TableHead >
                                                                            <tbody className="MuiTableBody-root">
                                                                                {
                                                                                    this.state.clientScopeData.map((row, index) => (
                                                                                        <React.Fragment key={row.clientScopeId}>
                                                                                            <tr className="MuiTableRow-root MuiTableRow-hover" style={this.trMain(index)}>
                                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={this.wordWrap} data-label='ScopeName'><div>{row.scopeName}</div></td>
                                                                                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" data-label='Action'><div className="action-icon"><button className="btn primary action delete" onClick={(e) => this.handleDeleteScope(e, row.clientScopeId, row.clientSequenceId)}>Delete</button></div></td>

                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    ))
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>)}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                )}
                                                {this.state.clientSequenceId > 0 && (<Tab eventKey="clientGrantType" title="Grant Type">
                                                    <div id="client-grant" role="tabpanel" aria-label="client-grant-tab">
                                                        <div className="tile-section form-section">
                                                            <form>
                                                                <div className="text text-secondary"><img src="/images/icon-exclamation-circle-orange.svg" alt="alert icon" /> Note: Grant Type can have multiple values</div>
                                                                <div className="row params">
                                                                    {this.renderSelect("grant", "Grant Type", this.state.grant, "info col-lg-4 col-md-4", true)}
                                                                    <div className="col-lg-2">
                                                                        <button className="btn primary action" onClick={e => this.handleAddGrantType(e)}>Add</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div className="info-wrapper results">
                                                                {this.state.clientGrantMessage && (<div className="col-lg-12">
                                                                    {this.state.clientGrantMessage}
                                                                </div>)}

                                                                {!this.state.clientGrantMessage && (<div className="col-lg-12">
                                                                    <table className="MuiTable-root" style={{ width: "100%" }}>
                                                                        <TableHead>
                                                                            <TableRow className="header">
                                                                                <TableCell key='GrantType' align='left' width='85%'>Grant Type</TableCell>
                                                                                <TableCell key='Action' align='right' width='15%'>Action</TableCell>
                                                                            </TableRow>
                                                                        </TableHead >
                                                                        <tbody className="MuiTableBody-root">
                                                                            {
                                                                                this.state.clientGrantData.map((row, index) => (
                                                                                    <React.Fragment key={row.clientGrantTypeId}>
                                                                                        <tr className="MuiTableRow-root MuiTableRow-hover" style={this.trMain(index)}>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={this.wordWrap} data-label='GrantType'><div>{row.grantTypeName}</div></td>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" data-label='Action'><div className="action-icon"><button className="btn primary action delete" onClick={(e) => this.handleDeleteGrantType(e, row.clientGrantTypeId, row.clientSequenceId)}>Delete</button></div></td>

                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>)}
                                                {this.state.clientSequenceId > 0 && (<Tab eventKey="clientSecret" title="Secret">
                                                    <div id="client-secret" role="tabpanel" aria-label="client-secret-tab">
                                                        <div className="tile-section form-section user-roles">
                                                            <form>
                                                                <div className="text text-secondary"><img src="/images/icon-exclamation-circle-orange.svg" alt="alert icon" /> Note: Secret can have multiple values</div>
                                                                <div className="row params">
                                                                  
                                                                    <div className="info col-lg-4">
                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="clientSecret" id={`customScope-label`}>
                                                                                Secret Name
                                                                            </label>
                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                <input id="clientSecret" type="text" name="clientSecret" maxLength="200" className={`MuiInputBase-input MuiInput-input ${this.state.clientSecretRequiredMessage ? "error-border" : ""}`} defaultValue={this.state.secret} onInput={this.onClientSecretChange} placeholder="Enter Secret Name" />
                                                                            </div>
                                                                            {this.state.clientSecretRequiredMessage && <small className="error-text">{this.state.clientSecretRequiredMessage}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-1">
                                                                        <button className="btn primary action" onClick={e => this.handleAddClientSecret(e)}>Add</button>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                            <div className="info-wrapper results">
                                                                {this.state.clientSecretMessage && (<div className="col-lg-12">
                                                                    {this.state.clientSecretMessage}
                                                                </div>)}

                                                                {!this.state.clientSecretMessage && (<div className="col-lg-12">
                                                                    <table className="MuiTable-root" style={{ width: "100%" }}>
                                                                        <TableHead>
                                                                            <TableRow className="header">
                                                                                <TableCell key='SecretValue' align='left' width='85%'>Secret Value</TableCell>
                                                                                <TableCell key='Action' align='right' width='15%'>Action</TableCell>
                                                                            </TableRow>
                                                                        </TableHead >
                                                                        <tbody className="MuiTableBody-root">
                                                                            {
                                                                                this.state.clientSecretData.map((row, index) => (
                                                                                    <React.Fragment key={row.clientSecretId}>
                                                                                        <tr className="MuiTableRow-root MuiTableRow-hover" style={this.trMain(index)}>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={this.wordWrap} data-label='SecretValue'><div>{row.clientSecretValue}</div></td>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" data-label='Action'><div className="action-icon"><button className="btn primary action delete" onClick={(e) => this.handleDeleteClientSecret(e, row.clientSecretId, row.clientSequenceId)}>Delete</button></div></td>

                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>)}
                                                {this.state.clientSequenceId > 0 && (<Tab eventKey="clientPostLogout" title="Post Logout Redirect URI">
                                                    <div id="client-postLogout" role="tabpanel" aria-label="client-postLogout-tab">
                                                        <div className="tile-section form-section user-roles">
                                                            <form>
                                                                <div className="text text-secondary"><img src="/images/icon-exclamation-circle-orange.svg" alt="alert icon" /> Note: Post Logout Redirect URI can have multiple values</div>
                                                                <div className="row params">
                                                                    <div className="info col-lg-4">
                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="clientPostLogoutRedirectUri" id={`customScope-label`}>
                                                                                Post Logout Redirect URI
                                                                            </label>
                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                <input id="clientPostLogoutRedirectUri" type="text" name="clientPostLogoutRedirectUri" maxLength="1200" className={`MuiInputBase-input MuiInput-input ${this.state.clientPostLogoutRedirectUriRequiredMessage ? "error-border" : ""}`} defaultValue={this.state.clientPostLogoutRedirectUri} onInput={this.onClientPostLogoutRedirectUriChange} placeholder="Enter Post Logout Redirect URI" />
                                                                            </div>
                                                                            {this.state.clientPostLogoutRedirectUriRequiredMessage && <small className="error-text">{this.state.clientPostLogoutRedirectUriRequiredMessage}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-1">
                                                                        <button className="btn primary action" onClick={e => this.handleAddClientPostLogoutRedirectUri(e)}>Add</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div className="info-wrapper results">
                                                                {this.state.clientPostLogoutRedirectUriMessage && (<div className="col-lg-12">
                                                                    {this.state.clientPostLogoutRedirectUriMessage}
                                                                </div>)}

                                                                {!this.state.clientPostLogoutRedirectUriMessage && (<div className="col-lg-12">
                                                                    <table className="MuiTable-root" style={{ width: "100%" }}>
                                                                        <TableHead>
                                                                            <TableRow className="header">
                                                                                <TableCell key='SecretValue' align='left' width='85%'>Post Logout Redirect URI</TableCell>
                                                                                <TableCell key='Action' align='right' width='15%'>Action</TableCell>
                                                                            </TableRow>
                                                                        </TableHead >
                                                                        <tbody className="MuiTableBody-root">
                                                                            {
                                                                                this.state.clientPostLogoutRedirectUriData.map((row, index) => (
                                                                                    <React.Fragment key={row.clientPostLogoutRedirectUriId}>
                                                                                        <tr className="MuiTableRow-root MuiTableRow-hover" style={this.trMain(index)}>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={this.wordWrap} data-label='PostLogoutRedirectUri'><div>{row.postLogoutRedirectUri}</div></td>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" data-label='Action'><div className="action-icon"><button className="btn primary action delete" onClick={(e) => this.handleDeleteClientPostLogoutRedirectUri(e, row.clientPostLogoutRedirectUriId, row.clientSequenceId)}>Delete</button></div></td>

                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>)}
                                                {this.state.clientSequenceId > 0 && (<Tab eventKey="clientRedirect" title="Client Redirect URI">
                                                    <div id="client-redirect" role="tabpanel" aria-label="client-redirect-tab">
                                                        <div className="tile-section form-section user-roles">
                                                            <form>
                                                                <div className="text text-secondary"><img src="/images/icon-exclamation-circle-orange.svg" alt="alert icon" /> Note: Client Redirect URI can have multiple values</div>
                                                                <div className="row params">
                                                                    <div className="info col-lg-4">
                                                                        <div className="MuiFormControl-root MuiTextField-root">
                                                                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="clientRedirectUri" id={`customScope-label`}>
                                                                                Redirect URI
                                                                            </label>
                                                                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                                                                <input id="clientRedirectUri" type="text" name="clientRedirectUri" maxLength="1200" className={`MuiInputBase-input MuiInput-input ${this.state.clientRedirectUriRequiredMessage ? "error-border" : ""}`} defaultValue={this.state.clientRedirectUri} onInput={this.onClientRedirectUriChange} placeholder="Enter Redirect URI" />
                                                                            </div>
                                                                            {this.state.clientRedirectUriRequiredMessage && <small className="error-text">{this.state.clientRedirectUriRequiredMessage}</small>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-1">
                                                                        <button className="btn primary action" onClick={e => this.handleAddClientRedirectUri(e)}>Add</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div className="info-wrapper results">
                                                                {this.state.clientRedirectUriMessage && (<div className="col-lg-12">
                                                                    {this.state.clientRedirectUriMessage}
                                                                </div>)}

                                                                {!this.state.clientRedirectUriMessage && (<div className="col-lg-12">
                                                                    <table className="MuiTable-root" style={{ width: "100%" }}>
                                                                        <TableHead>
                                                                            <TableRow className="header">
                                                                                <TableCell key='SecretValue' align='left' width='85%'>Redirect URI</TableCell>
                                                                                <TableCell key='Action' align='right' width='15%'>Action</TableCell>
                                                                            </TableRow>
                                                                        </TableHead >
                                                                        <tbody className="MuiTableBody-root">
                                                                            {
                                                                                this.state.clientRedirectUriData.map((row, index) => (
                                                                                    <React.Fragment key={row.clientRedirectUriId}>
                                                                                        <tr className="MuiTableRow-root MuiTableRow-hover" style={this.trMain(index)}>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={this.wordWrap} data-label='RedirectUri'><div>{row.redirectUri}</div></td>
                                                                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignRight" data-label='Action'><div className="action-icon"><button className="btn primary action delete" onClick={(e) => this.handleDeleteClientRedirectUri(e, row.clientRedirectUriId, row.clientSequenceId)}>Delete</button></div></td>

                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>)}

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

}