import React from 'react';

const Select = ({ name, label, options, classSize, error, value, readOnly, disabled, isRequired, onChange }) => {

    return (
        <div className={`${classSize}`}>
            <div className="MuiFormControl-root MuiTextField-root">
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" htmlFor={name} id={`${name}-label`}>
                    {label}
                </label>
                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                    <select name={name} id={name} value={value} onChange={onChange} readOnly={readOnly} disabled={disabled} className={`MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input ${error ? "error-border" : ""}`}>
                        {isRequired && <option value='' disabled />}
                        {/* todo: update code to pull the first option and make it disabled to use as placeholder?
                         todo: may need to update value in select tag for same first option value */}
                        {options.map((option) => (
                            <option key={option.id} value={option.id} >
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <div className="select-arrow">
                        <img src="images/icon-arrow-down-gray.svg" className="select-down-arrow" alt="select arrow" />
                    </div>
                </div>
            </div>
            {isRequired && error && <small className="error-text">{error}</small>}
        </div>
    );
};
export default Select;